import createReducer from 'redux/utils/createReducer';
import {
  IDynamicData,
  IDynamicPayload,
  IRootActionTypes,
} from 'constants/types';
import { ActionTypes } from 'constants/actionTypes';

const initialState: IDynamicPayload<IDynamicData> = {
  loading: false,
  data: null,
  success: false,
  error: null,
  failed: false,
  current: null,
};

export const actions = (actionName: ActionTypes) => ({
  [`${actionName}_PENDING`](state: IRootActionTypes): IDynamicPayload<IDynamicData> {
    return {
      ...state,
      loading: true,
      success: false,
      error: null,
      failed: false,
    };
  },
  [`${actionName}_FULFILLED`](state: IRootActionTypes, action: IRootActionTypes): IDynamicPayload<IDynamicData> {
    const { payload } = action;
    return {
      ...initialState,
      loading: false,
      data: payload?.data,
      success: true,
      current: payload?.current,
    };
  },
  [`${actionName}_REJECTED`](state: IRootActionTypes, action: IRootActionTypes): IDynamicPayload<IDynamicData> {
    return {
      ...initialState,
      loading: false,
      error: action.payload,
      failed: true,
    };
  },
  [`${actionName}_CLEAR`](): IDynamicPayload<IDynamicData> {
    return initialState;
  },
});

const createThunkReducer = (actionName: ActionTypes) => createReducer(initialState, actions(actionName));

export default createThunkReducer;
