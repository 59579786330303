import { ActionTypes } from 'constants/actionTypes';
import { getCurrentLangAliasByCookies } from 'utils/languages';

// Types
export const LANG_EDITED = `${ActionTypes.LANG}_EDITED`;

// Interfaces
export interface ILangPayload {
  lang: string;
}

export interface ILangActionTypes {
  type: string;
  payload: string;
}

// Init
const initialState: ILangPayload = {
  lang: getCurrentLangAliasByCookies(),
};

// Reducer
const langReducer = (state = initialState, action: ILangActionTypes): ILangPayload => {
  switch (action.type) {
    case LANG_EDITED:
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
};

export default langReducer;
