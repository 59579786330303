import { ActionTypes } from 'constants/actionTypes';

// Types
export const MODAL_OPEN = `${ActionTypes.MODAL}_OPEN`;
export const MODAL_CLOSE = `${ActionTypes.MODAL}_CLOSE`;

// Interfaces
export interface IModalPayload {
  isOpen?: boolean
  title: string
  onSuccess: () => void
}
export interface IModalActionTypes {
  type: string;
  payload: IModalPayload;
}

// Init
const initialState: IModalPayload = {
  onSuccess: () => {},
  title: '',
  isOpen: false,
};

// Reducer
const modalReducer = (state = initialState, action: IModalActionTypes): IModalPayload => {
  switch (action.type) {
    case MODAL_OPEN:
      return <IModalPayload>{
        title: action.payload.title,
        isOpen: true,
        onSuccess: action.payload.onSuccess,
      };
    case MODAL_CLOSE:
      return {
        title: '',
        isOpen: false,
        onSuccess: () => {},
      };
    default:
      return state;
  }
};

export default modalReducer;
