import * as DEFAULTS from 'constants/defaults';
import { APILinks } from 'constants/api';
import { Languages } from 'constants/types';

export interface ILangItem {
  alias: Languages | string,
  label: {
    short: string
    full: string
  }
  landing: APILinks | string
}

export const languages: ILangItem[] = [
  {
    alias: Languages.KZ,
    label: {
      short: 'Қз',
      full: 'Қазақша',
    },
    landing: APILinks.AUTH_LINK_KZ,
  },
  {
    alias: Languages.RU,
    label: {
      short: 'Ру',
      full: 'Русский',
    },
    landing: APILinks.AUTH_LINK_RU,
  },
  {
    alias: Languages.EN,
    label: {
      short: 'En',
      full: 'English',
    },
    landing: APILinks.AUTH_LINK_EN,
  },
];

export const defaultLanguage: ILangItem = {
  alias: DEFAULTS.LANG,
  label: {
    short: DEFAULTS.LANG_TEXT_SHORT,
    full: DEFAULTS.LANG_TEXT_FULL,
  },
  landing: DEFAULTS.LANDING,
};
