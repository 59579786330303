import { lazy } from 'react';
import { RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import { vsprintf } from 'sprintf-js';
import RoutePath from 'routes/constants/routes';
import { HeaderType } from 'components/Header/main/HeaderMain';

const RootLayout = lazy(() => import('layouts/root/RootLayout'));
const HomePage = lazy(() => import('pages/home/HomePage'));
const ErrorPage = lazy(() => import('pages/error/ErrorPage'));
const ErrorByAliasPage = lazy(() => import('pages/errorByAlias/ErrorByAliasPage'));
const OfferPage = lazy(() => import('pages/offer/OfferPage'));
const AboutPage = lazy(() => import('pages/about/AboutPage'));
const JournalsPage = lazy(() => import('pages/journals/JournalsPage'));
const JournalsDetailPage = lazy(() => import('pages/journalsDetail/JournalsDetailPage'));
const JournalsIssuesBookmarksPage = lazy(() => import('pages/journalsIssuesBookmarks/JournalsIssuesBookmarksPage'));
const JournalsIssuesDetailPage = lazy(() => import('pages/journalsIssuesDetail/JournalsIssuesDetailPage'));
const JournalsIssuesDetailPromoPage = lazy(() => import('pages/journalsIssuesDetailPromo/JournalsIssuesDetailPromoPage'));
const ArticlesPage = lazy(() => import('pages/articles/ArticlesPage'));
const ArticlesDetailPage = lazy(() => import('pages/articlesDetail/ArticlesDetailPage'));
const NewsPage = lazy(() => import('pages/news/NewsPage'));
const NewsDetailPage = lazy(() => import('pages/newsDetail/NewsDetailPage'));
const BooksPage = lazy(() => import('pages/books/BooksPage'));
const BooksDetailPage = lazy(() => import ('pages/booksDetail/BooksDetailPage'));
const BooksReadPage = lazy(() => import ('pages/booksRead/BooksReadPage'));
const FaqPage = lazy(() => import('pages/faq/FaqPage'));
const SearchLayout = lazy(() => import('layouts/search/SearchLayout'));
const SearchCategoriesPage = lazy(() => import('pages/searchCategories/SearchCategoriesPage'));
const ProfileLayout = lazy(() => import('layouts/profile/ProfileLayout'));
const ProfilePage = lazy(() => import('pages/profile/ProfilePage'));
const ProfileFavoritesPage = lazy(() => import('pages/profileFavorites/ProfileFavoritesPage'));
const ProfileBookmarksPage = lazy(() => import('pages/profileBookmarks/ProfileBookmarksPage'));
const ProfileHistoryPage = lazy(() => import('pages/profileHistory/ProfileHistoryPage'));
const ProfileInterestsPage = lazy(() => import('pages/profileInterests/ProfileInterestsPage'));
const LovePage = lazy(() => import('pages/love/LovePage'));
const AccountPage = lazy(() => import('pages/account/AccountPage'));

export interface ICustomRouteConfig extends RouteConfig {
  titlePath?: string[]
  titleExtraPath?: string[]
  headerType?: HeaderType
  hideHeader?: boolean
  hideFooter?: boolean
  hideNavbar?: boolean
  hideSearch?: boolean
  hideSearchMobile?: boolean
  hideAudio?: boolean
  fixedHeight?: boolean
  fixedHeightLaptop?: boolean
}
export interface IComponentPage extends RouteConfigComponentProps {}

const routes: ICustomRouteConfig[] = [
  {
    component: RootLayout,
    path: RoutePath.ROOT_PATH,
    exact: false,
    routes: [
      {
        path: RoutePath.ROOT_PATH,
        titlePath: ['pages', 'main'],
        headerType: HeaderType.PRIMARY,
        exact: true,
        component: HomePage,
      },
      {
        path: RoutePath.LOVE_PATH,
        titlePath: ['pages', 'articles'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        exact: true,
        component: LovePage,
      },
      {
        path: RoutePath.JOURNAL_PROMO_PATH,
        titlePath: ['pages', 'journals'],
        headerType: HeaderType.SECONDARY,
        hideHeader: true,
        hideFooter: true,
        hideNavbar: true,
        fixedHeight: true,
        hideAudio: true,
        exact: true,
        component: JournalsIssuesDetailPromoPage,
      },
      {
        path: RoutePath.ARTICLE_PROMO_PATH,
        titlePath: ['pages', 'articles'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        exact: true,
        component: ArticlesDetailPage,
      },
      {
        path: `${RoutePath.JOURNALS_PATH}/:categoryId/detail/:journalId/issues/:issueId/bookmarks`,
        titlePath: ['pages', 'journals'],
        headerType: HeaderType.SECONDARY,
        hideHeader: true,
        hideFooter: true,
        hideNavbar: true,
        fixedHeight: true,
        hideAudio: true,
        exact: true,
        component: JournalsIssuesBookmarksPage,
      },
      {
        path: `${RoutePath.JOURNALS_PATH}/:categoryId/detail/:journalId/issues/:issueId`,
        titlePath: ['pages', 'journals'],
        headerType: HeaderType.SECONDARY,
        hideHeader: true,
        hideFooter: true,
        hideNavbar: true,
        fixedHeight: true,
        hideAudio: true,
        exact: true,
        component: JournalsIssuesDetailPage,
      },
      {
        path: `${RoutePath.JOURNALS_PATH}/:categoryId/detail/:journalId`,
        titlePath: ['pages', 'journals'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        exact: true,
        component: JournalsDetailPage,
      },
      {
        path: `${RoutePath.JOURNALS_PATH}/:categoryId`,
        titlePath: ['pages', 'journals'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        exact: true,
        component: JournalsPage,
      },
      {
        path: `${RoutePath.ARTICLES_PATH}/:categoryId/detail/:journalId/issues/:issueId/articles/:articleId`,
        titlePath: ['pages', 'articles'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        exact: true,
        component: ArticlesDetailPage,
      },
      {
        path: `${RoutePath.ARTICLES_PATH}/:categoryId`,
        titlePath: ['pages', 'articles'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        exact: true,
        component: ArticlesPage,
      },
      {
        path: `${RoutePath.NEWS_PATH}/:categoryId/detail/:newsId`,
        titlePath: ['pages', 'news'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        exact: true,
        component: NewsDetailPage,
      },
      {
        path: vsprintf(RoutePath.BOOKS_CATEGORIES_BY_ID_PATH, [':categoryId']),
        titlePath: ['pages', 'books'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        exact: true,
        component: BooksPage,
      },
      {
        path: vsprintf(RoutePath.BOOKS_DETAIL_PATH, [':bookId']),
        titlePath: ['pages', 'books'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        exact: true,
        component: BooksDetailPage,
      },
      {
        path: vsprintf(RoutePath.BOOKS_READ_PATH, [':bookId']),
        titlePath: ['pages', 'books'],
        headerType: HeaderType.SECONDARY,
        hideHeader: true,
        hideFooter: true,
        hideNavbar: true,
        fixedHeight: true,
        exact: true,
        component: BooksReadPage,
      },
      {
        path: `${RoutePath.NEWS_PATH}/:categoryId`,
        titlePath: ['pages', 'news'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        exact: true,
        component: NewsPage,
      },
      {
        path: RoutePath.OFFER_PATH,
        titlePath: ['pages', 'offer'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        exact: true,
        component: OfferPage,
      },
      {
        path: RoutePath.ABOUT_PATH,
        titlePath: ['pages', 'about'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        exact: true,
        component: AboutPage,
      },
      {
        path: RoutePath.FAQ_PATH,
        titlePath: ['pages', 'faq'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        exact: true,
        component: FaqPage,
      },
      {
        path: RoutePath.SEARCH_PATH,
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        hideSearch: true,
        exact: false,
        component: SearchLayout,
        routes: [
          {
            path: `${RoutePath.SEARCH_PATH}/:searchCategory`,
            titlePath: ['pages', 'search'],
            headerType: HeaderType.SECONDARY,
            hideFooter: true,
            hideSearchMobile: true,
            exact: true,
            component: SearchCategoriesPage,
          },
        ],
      },
      {
        path: RoutePath.PROFILE_PATH,
        titlePath: ['pages', 'profile'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        fixedHeightLaptop: true,
        exact: false,
        component: ProfileLayout,
        routes: [
          {
            path: RoutePath.PROFILE_PATH,
            titlePath: ['pages', 'profile'],
            titleExtraPath: ['words', 'managePermissions'],
            headerType: HeaderType.SECONDARY,
            hideFooter: true,
            fixedHeightLaptop: true,
            exact: true,
            component: ProfilePage,
          },
          {
            path: RoutePath.PROFILE_FAVORITES_PATH,
            titlePath: ['pages', 'profile'],
            titleExtraPath: ['pages', 'favorites'],
            headerType: HeaderType.SECONDARY,
            hideFooter: true,
            fixedHeightLaptop: true,
            exact: true,
            component: ProfileFavoritesPage,
          },
          {
            path: `${RoutePath.PROFILE_BOOKMARKS_PATH}/:bookmarksAlias`,
            titlePath: ['pages', 'profile'],
            titleExtraPath: ['pages', 'bookmarks'],
            headerType: HeaderType.SECONDARY,
            hideFooter: true,
            fixedHeightLaptop: true,
            exact: true,
            component: ProfileBookmarksPage,
          },
          {
            path: `${RoutePath.PROFILE_HISTORY_PATH}/:historyAlias`,
            titlePath: ['pages', 'profile'],
            titleExtraPath: ['pages', 'history'],
            headerType: HeaderType.SECONDARY,
            hideFooter: true,
            fixedHeightLaptop: true,
            exact: true,
            component: ProfileHistoryPage,
          },
          {
            path: RoutePath.PROFILE_INTERESTS_PATH,
            titlePath: ['pages', 'profile'],
            titleExtraPath: ['pages', 'interests'],
            headerType: HeaderType.SECONDARY,
            hideFooter: true,
            fixedHeightLaptop: true,
            exact: true,
            component: ProfileInterestsPage,
          },
        ],
      },
      {
        path: RoutePath.ACCOUNT_PATH,
        titlePath: ['pages', 'main'],
        headerType: HeaderType.SECONDARY,
        hideHeader: true,
        hideFooter: true,
        hideNavbar: true,
        component: AccountPage,
      },
      {
        path: `${RoutePath.ERROR_PATH}/:errorAlias`,
        titlePath: ['pages', 'error'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        component: ErrorByAliasPage,
      },
      {
        path: RoutePath.NOT_FOUND_PATH,
        titlePath: ['pages', 'error'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        component: ErrorPage,
      },
      {
        path: RoutePath.ERROR_PATH,
        titlePath: ['pages', 'error'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        component: ErrorPage,
      },
      {
        titlePath: ['pages', 'error'],
        headerType: HeaderType.SECONDARY,
        hideFooter: true,
        component: ErrorPage,
      },
    ],
  },
];

export default routes;
