import styled from 'styled-components/macro';
import Loader from 'components/Loader/Loader';

export const SearchCategoriesDropdownWrap = styled.div`
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  background: ${({ theme }) => theme.background.fields.search.hover};
  color: ${({ theme }) => theme.color.primary};
  padding: 9px 0;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15);
  border-radius: ${({ theme }) => `0 0 ${theme.borderRadius.field.search} ${theme.borderRadius.field.search}`};
`;
export const SearchCategoriesDropdownItemCount = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-left: auto;
  color: ${({ theme }) => theme.palette.greyMiddle};
`;
export const SearchCategoriesDropdownItem = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  width: 100%;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  line-height: 18px;
  padding: 19px;
`;
export const SearchCategoriesDropdownItemLabel = styled.span`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  width: 100%;
`;
export const SearchCategoriesDropdownItemLoader = styled(Loader)`
  margin: 0 0 0 auto;
`;
