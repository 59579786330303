import { ERROR_CLEAR, ERROR_EDITED, IErrorPayload } from 'redux/reducers/errorReducer';

export const errorAction = (payload: IErrorPayload) => ({
  type: ERROR_EDITED,
  payload,
});

export const errorClearAction = () => ({
  type: ERROR_CLEAR,
});
