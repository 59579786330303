import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import rootReducer from 'redux/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const middlewares = [thunk, promise];
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

export default store;
