import has from 'lodash.has';
import { IDynamicData, IDynamicPayload, IRootActionTypes } from 'constants/types';

const createReducer = (
  initialState: IDynamicPayload<IDynamicData>,
  handlers: IRootActionTypes,
) => (
  state = initialState,
  action: IRootActionTypes,
) => {
  if (has(handlers, action.type)) {
    return handlers[action.type](state, action);
  }

  return state;
};

export default createReducer;
