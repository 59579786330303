import { useState, useEffect } from 'react';

const useDebounce = <V = any>(value: V, delay: number): V => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value],
  );

  return debouncedValue;
};

export default useDebounce;
