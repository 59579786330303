import React from 'react';
import Button from 'components/Button/Button';
import { ILangItem, languages } from 'constants/languages';
import { setLang } from 'redux/actions/static/langActions';
import { useDispatch } from 'react-redux';
import { ILangBannerProps } from './LangBannerTypes';
import {
  LangBannerWrap,
  LangBannerLogo,
  LangBannerButtons,
} from './LangBannerStyles';

function LangBanner(props: ILangBannerProps) {
  const { handleClose } = props;

  const dispatch = useDispatch();

  const handleLangAccept = (alias: ILangItem['alias']) => {
    dispatch(setLang(alias));
    handleClose();
  };

  const langListEl = languages.map(({ alias, label: { full: label } }) => (
    <Button
      onClick={() => handleLangAccept(alias)}
      key={alias}
      fullWidth
    >
      {label}
    </Button>
  ));

  return (
    <LangBannerWrap withPaddings>
      <LangBannerLogo />
      <LangBannerButtons>
        {langListEl}
      </LangBannerButtons>
    </LangBannerWrap>
  );
}

export default LangBanner;
