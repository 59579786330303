export const BOOK_READ_FORMAT = 'epub';
export const BOOK_LISTEN_FORMAT = 'mp3';

export enum BooksCollectionHash {
  POPULAR = '033b8ceb062ab536ffb30b37f37478f9',
  RECOMMENDED = 'a2d067bae66a41616b405a46a7f342f9',
  PARENTS = 'eea5e3c20b3302c9b05a1ee8ee6e29b0',
  NEW = '6df99700f6f7d5d9e0f2091067e4688f',
}

interface IBooksAudioSpeedItem {
  rate: number
  label: string[] | string
}

export const booksAudioSpeed: IBooksAudioSpeedItem[] = [
  {
    rate: 0.25,
    label: '0.25',
  },
  {
    rate: 0.5,
    label: '0.5',
  },
  {
    rate: 0.75,
    label: '0.75',
  },
  {
    rate: 1,
    label: ['words', 'audioNormal'],
  },
  {
    rate: 1.25,
    label: '1.25',
  },
  {
    rate: 1.5,
    label: '1.5',
  },
  {
    rate: 1.75,
    label: '1.75',
  },
  {
    rate: 2,
    label: '2',
  },
];
