import { ISearchCategoriesItem, searchCategoriesAllResultsItem, searchCategoriesUniqItems } from 'constants/search';
import map from 'lodash.map';
import sumBy from 'lodash.sumby';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { IRootState } from 'constants/types';
import find from 'lodash.find';
import uniq from 'lodash.uniq';
import filter from 'lodash.filter';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

interface IListItem extends ISearchCategoriesItem {
  count: number
  preCount: number
  countLoading: boolean
  preCountLoading: boolean
  countQuery: string | string[]
  preCountQuery: string | string[]
}
export interface IUseSearchCategories {
  list: IListItem[]
}
interface IUseSearchCategoriesProps {
  allResultsFirst?: boolean,
}

const useSearchCategories = (props?: IUseSearchCategoriesProps): IUseSearchCategories => {
  const allResultsFirst = get(props, ['allResultsFirst'], false);

  const rootState = useSelector((state: IRootState) => state);

  const history = useHistory();
  const search = get(history, ['location', 'search'], '');

  const queries = queryString.parse(search);
  const searchQuery = get(queries, ['search'], '');
  const searchValue = typeof searchQuery === 'string' ? searchQuery : '';

  const handleGetCountByStates = (stateName: ISearchCategoriesItem['countState']) => {
    if (Array.isArray(stateName)) {
      return sumBy(stateName, (itemSum) => {
        const currentCount = get(rootState, [itemSum, 'data', 'count'], 0);
        const currentCountLoading = get(rootState, [itemSum, 'loading']);
        if (currentCountLoading) {
          return 0;
        }
        return currentCount;
      });
    }
    return get(rootState, [stateName, 'data', 'count'], 0);
  };

  const handleGetLoadingByStates = (stateName: ISearchCategoriesItem['countState']) => {
    if (Array.isArray(stateName)) {
      return !!(find(stateName, (itemState) => get(rootState, [itemState, 'loading'])));
    }
    return get(rootState, [stateName, 'loading'], false);
  };

  const handleGetQueryByStates = (stateName: ISearchCategoriesItem['countState']) => {
    if (Array.isArray(stateName)) {
      const mappedQueries = map(stateName, (itemSum) => {
        const count = get(rootState, [itemSum, 'data', 'count'], 0);
        const loading = get(rootState, [itemSum, 'loading'], false);
        const query = get(rootState, [itemSum, 'data', 'query'], '');

        if (!loading && (count > 0) && searchValue !== query) {
          return get(rootState, [itemSum, 'data', 'query'], '');
        }
        return '';
      });
      const uniqQueries = uniq(mappedQueries);
      return filter(uniqQueries, (uItem) => uItem);
    }

    if (!Array.isArray(stateName)) {
      const count = get(rootState, [stateName, 'data', 'count'], 0);
      const loading = get(rootState, [stateName, 'loading'], false);
      const query = get(rootState, [stateName, 'data', 'query'], '');

      if (!loading && (count > 0) && searchValue !== query) {
        return get(rootState, [stateName, 'data', 'query'], '');
      }
      return '';
    }

    return '';
  };

  const categories: ISearchCategoriesItem[] = allResultsFirst
    ? [
      searchCategoriesAllResultsItem,
      ...searchCategoriesUniqItems,
    ] : [
      ...searchCategoriesUniqItems,
      searchCategoriesAllResultsItem,
    ];

  const list = map(categories, (item) => {
    const { countState, preCountState } = item;

    return {
      ...item,
      countLoading: handleGetLoadingByStates(countState),
      preCountLoading: handleGetLoadingByStates(preCountState),
      count: handleGetCountByStates(countState),
      preCount: handleGetCountByStates(preCountState),
      countQuery: handleGetQueryByStates(countState),
      preCountQuery: handleGetQueryByStates(preCountState),
    };
  });

  return {
    list,
  };
};

export default useSearchCategories;
