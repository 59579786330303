export const firebaseProjectToken = '99b1d518-9d7c-4ea6-9a93-4b833f3f4fd1';

export const firebaseVapidKey = 'BGfS5Jo4Up7ezYEQrni9po-1aob20C_TuYLrybsTYegK6GUVpDaONMhp4zAl3gUN7Xd2CoLdo74qjGziaw37faw';

export const firebaseConfig = {
  apiKey: 'AIzaSyB3-335oHaJXF2aEvPjYPkovQELQbBorV8',
  authDomain: 'mobipress-53e87.firebaseapp.com',
  projectId: 'mobipress-53e87',
  storageBucket: 'mobipress-53e87.appspot.com',
  messagingSenderId: '1072705391542',
  appId: '1:1072705391542:web:04b59922594e9d967a0f17',
};
