import { ActionTypes } from 'constants/actionTypes';
import { getCurrentLangAliasByCookies } from 'utils/languages';

export const ERROR_EDITED = `${ActionTypes.ERROR}_EDITED`;
export const ERROR_CLEAR = `${ActionTypes.ERROR}_CLEAR`;

export interface IErrorPayload {
  status: number | string | null
  message: string | string[] | null
  from: string | null
  lang: string
}
export interface IErrorActionTypes {
  type: string;
  payload: IErrorPayload;
}

const initialState: IErrorPayload = {
  status: null,
  message: null,
  from: null,
  lang: getCurrentLangAliasByCookies(),
};

const modalReducer = (state = initialState, action: IErrorActionTypes): IErrorPayload => {
  switch (action.type) {
    case ERROR_EDITED:
      return <IErrorPayload>action.payload;
    case ERROR_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default modalReducer;
