import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'redux/store';
import ThemeProvider from 'themes/provider/ThemeProvider';
import App from 'components/App/App';
import Loader from 'components/Loader/Loader';

const Root = () => (
  <Provider store={store}>
    <ThemeProvider>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <App />
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);

export default Root;
