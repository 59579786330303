import React, { ReactElement } from 'react';
import { ThemeProvider as Provider } from 'styled-components/macro';
import themes from 'themes/constants/themes';
import MainCSS from 'themes/styles/MainCSS';
import 'normalize.css';
import 'themes/fonts/stylesheet.css';
import 'swiper/swiper-bundle.css';

// Interfaces
interface IThemeProvider {
  children: ReactElement | ReactElement[]
}

// Component
const ThemeProvider = (props: IThemeProvider) => {
  const { children } = props;

  return (
    <Provider theme={themes}>
      <>
        <MainCSS />
        {children}
      </>
    </Provider>
  );
};

export default ThemeProvider;
