import { createGlobalStyle } from 'styled-components/macro';
import { ITheme } from '../constants/themes';

export default createGlobalStyle<{ theme: ITheme }>`
    #root {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        min-height: 100vh;
        z-index: 1;
    }
    * {
        scrollbar-width: thin;
        scrollbar-color: ${({ theme }) => theme.background.thumb};
        ::-webkit-scrollbar{
            width: ${({ theme }) => theme.width.thumb};
            height: ${({ theme }) => theme.height.thumb};
        }
        ::-webkit-scrollbar-thumb{
            background: ${({ theme }) => theme.background.thumb};
        }
    }
    *,
    *:before,
    *:after {
        box-sizing: border-box;
        overflow-wrap: break-word;
    }
    *:focus {
        outline: none !important;
    }
    *:active {
        outline:none !important;
    }
    button{
        cursor: pointer;
        border-radius: 0;
        padding: 0;
        margin: 0;
        border: none;
        -webkit-appearance: none;
        &:focus {
            outline: none !important;
        }
    }
    audio, video {
        display: block;
    }
    img {
        border: none;
    }
    * > img{
        max-width: 100%;
    }
    body {
        font-family: ${({ theme }) => theme.fontFamily.primary};
        background: ${({ theme }) => theme.background.primary};
        color: ${({ theme }) => theme.color.primary};
        font-size: 18px;
        line-height: 1.2;
        overflow-x: hidden;
        overflow-y: scroll;
        min-width: 320px;
    }
    a {
        text-decoration: none;
        *,
        &:hover,
        &:link,
        &:active,
        &:visited {
            color: inherit;
        }
    }

    p, dd, dl, figure, blockquote {
        margin: 0;
    }
    ul, ol{
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        font-size: inherit;
        font-weight: 500;
        color: ${({ theme }) => theme.color.primary};
    }
    button, input, optgroup, select, textarea {
        padding: 0;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
        background-color: transparent;
        font: inherit;
        color: inherit;
        letter-spacing: inherit;
        appearance: none !important;
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
    }
    button{
        cursor: pointer;
        border-radius: 0;
        user-select: none;
        -webkit-appearance: none;
        &:focus {
            outline: none !important;
        }
    }
    
    .display-enter {
        opacity: 0;
    }
    .display-enter-active {
        opacity: 1;
        transition: opacity 200ms;
    }
    .display-exit {
        opacity: 1;
    }
    .display-exit-active {
        opacity: 0;
        transition: opacity 200ms;
    }

    //@media screen and (-webkit-min-device-pixel-ratio: 0) {
    //    select:focus, textarea:focus, input:focus {
    //        font-size: 16px;
    //    }
    //}
`;
