import React from 'react';
import ReactDOM from 'react-dom';
import { initializeApp } from 'firebase/app';
import 'react-app-polyfill/ie11';
import Root from 'components/Root/Root';
import portals from 'portals';
import { firebaseConfig } from 'constants/firebase';
import { firebaseRegistration } from 'utils/firebase';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Firebase
initializeApp(firebaseConfig);

// Portals
portals.map((item: string) => {
  const el = document.createElement('div');
  el.id = item;
  document.body.appendChild(el);
  return null;
});

// Root
ReactDOM.render(
  <Root />,
  document.getElementById('root'),
);

// Service worker
serviceWorkerRegistration.register({
  onSuccess: firebaseRegistration,
  onUpdate: firebaseRegistration,
});
