import React, { useRef } from 'react';
import { ReactComponent as BackButtonSVG } from 'media/icons/chevron-back.svg';
import { useHistory } from 'react-router-dom';
import { BackButtonWrap } from './BackButtonStyles';

const BackButton = () => {
  const history = useHistory();
  const backBtnRef = useRef<HTMLDivElement>(null);

  return (
    <BackButtonWrap
      onClick={history.goBack}
      ref={backBtnRef}
    >
      <BackButtonSVG />
    </BackButtonWrap>
  );
};

export default BackButton;
