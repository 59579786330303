import { States } from 'constants/states';
import { ILangActionTypes, ILangPayload } from 'redux/reducers/langReducer';
import { IModalActionTypes, IModalPayload } from 'redux/reducers/modalReducer';
import { IAudioActionTypes, IAudioPayload } from 'redux/reducers/audioReducer';
import { IErrorActionTypes, IErrorPayload } from 'redux/reducers/errorReducer';
import { IProfileFiltersActionTypes, IProfileFiltersPayload } from 'redux/reducers/profileFiltersReducer';
import { IAccessPayload, IAccessActionTypes } from 'redux/reducers/accessReducer';
import { AxiosRequestConfig } from 'axios';

// Langs
export enum Languages {
  RU = 'ru',
  KZ = 'kz',
  EN = 'en',
}

// Profile
export interface IProfileData {
  ageConfirmed: boolean | null
  email?: string;
  lastname: string | null
  msisdn: number
  name?: string;
  photo?: string;
  subscribeLock: boolean
  subscriptionDate?: string | null
  tryDaysLeft: string | null
  status: {
    message: string
    state: ProfileSubscriptionState
  }
}
export enum ProfileSubscriptionState {
  PAID = 'paid',
  TRIAL = 'trial',
  IS_SUSPENDED = 'is_suspended',
  NOT_EXISTS = 'not-exists',
  UNACTIVE = 'unactive',
  PARTIAL_PAID = 'partial_paid',
  MONTHLY_PAID = 'monthly_paid',
}
export enum ProfileFiltersAliases {
  FAVORITES_JOURNALS = 'favoritesJournals',
  BOOKMARKS_JOURNALS = 'bookmarksJournals',
  BOOKMARKS_ARTICLES = 'bookmarksArticles',
  HISTORY_JOURNALS = 'historyJournals',
  HISTORY_ARTICLES = 'historyArticles',
}
export interface IProfilePostData {
  result: boolean
  profile: IProfileData
}
export interface IProfileInterestsItem {
  checked?: boolean;
  id: number;
  image?: string;
  name?: string;
}
export interface IProfileBookmarksPagesItem {
  createdAt: string | null
  hasArticle: boolean
  image: string | null
  issue: string
  journalName: string
  page: number
  pageId: number
  preview: string | null
  smallImage: string | null
  title: string | null
}
export interface IProfileBookmarksIssuesItem {
  issueId: number
  issueName: string
  pages:IProfileBookmarksPagesItem[]
}
export interface IProfileBookmarksJournalsItem {
  issues: IProfileBookmarksIssuesItem[];
  journalId: number;
  journalName: string;
}
export type IProfileInterestsData = IProfileInterestsItem[];
export type IProfileBookmarksJournalsData = IProfileBookmarksJournalsItem[];
export type IProfileBookmarksArticlesData = IArticlesItem[];
export interface IProfileJournalsFiltersData {
  id: number,
  name: string,
}
export interface IProfileArticlesFiltersData {
  id: number,
  name: string,
}

// DynamicPages
export interface IFaqData {
  faq: string
}
export interface IOfferData {
  agreement: string;
}
export interface IAboutData {
  aboutService: string;
}
export interface IErrorByAliasData {
  [key: string]: string;
}

// Journals and Issues
export interface IJournalsCategoriesItem {
  forAdults: boolean
  id: number
  manual: boolean
  name: string
  sortByOrder: boolean
  previewPath?: string | null
}
export type IJournalsCategoriesData = IJournalsCategoriesItem[];
export interface IJournal {
  id: number;
  name: string;
  description: string;
  descriptionCantUp?: null | string;
}
export interface IEachIssue {
  id: number;
  name: string;
  hasArticles: boolean;
  amountOfPages: number;
  year: number;
  bookmarks?: never[];
  releaseDate: string;
  releasedAt: string;
  cover: string;
  thumbMobile: string;
  coverMobile: string;
  journalId: number;
  freeIssue: boolean;
  journal?: IJournal;
  inBookmark?: boolean;
}
export interface IJournalsItem {
  categoryId: number;
  categoryName: string;
  inFavorite: boolean;
  lastIssue: IEachIssue;
  recommended?: boolean;
  inBookmark?: false;
  favorite?: string[];
}
export type IJournalsData = IJournalsItem[];
export type IJournalsIssuesData = IEachIssue[];
export type IJournalsIssuesArticlesData = IArticlesItem[];

export interface ISearchCountData {
  count: number
  query: string
}

export interface IProfileHistoryJournalsIssuesItem {
  categoryId: number;
  categoryName: string;
  createdAt: string;
  description: string;
  id: number;
  issue: IEachIssue;
  name: string;
}
export type IProfileHistoryJournalsIssuesData = IProfileHistoryJournalsIssuesItem[];

export interface IJournalsIssuesDetailPage {
  articleIndex: number;
  hasArticle: boolean;
  id: number;
  inBookmark: boolean;
  page: number;
}
export interface IJournalsIssuesDetailData {
  categoryId: number;
  categoryName: string;
  id: number;
  journalId: number;
  journalName: string;
  name: string;
  inBookmark: boolean;
  pages: IJournalsIssuesDetailPage[];
}
export interface IJournalsIssuesDetailViewData {
  category_id: number
  category_name: string
  id: number
  journal_id: number
  pages_preview: string
}

// Articles
export interface IArticlesCategoriesItem {
  forAdults: boolean
  id: number
  manual: boolean
  name: string
  sortByOrder: boolean
  previewPath?: string | null
}
export type IArticlesCategoriesData = IArticlesCategoriesItem[];
export interface IArticlesItem {
  id: number;
  journalId: number;
  journalName: string;
  issueId: number;
  categoryId: number;
  categoryName?: string;
  image: string;
  smallImage: string;
  title: string;
  shortText: string;
  text: string;
  inBookmark?: boolean;
  recommended?: boolean;
  publishedAt: string;
  query?: string,
}
export type IArticlesData = IArticlesItem[];
export type IArticlesItemData = IArticlesItem;

// News
export interface INewsItem {
  ago: string
  body: string
  categoryId: number
  catgoryName?: string
  categoryName?: string
  description: string
  id: number
  image: string
  inBookmark: boolean
  preview: string
  publishDate: string
  readingTime: number
  src: string
  anotherNews?: INewsItem[]
}
export type INewsData = INewsItem[];
export interface INewsCategoriesItem {
  id: number
  name: string
}
export type INewsCategoriesData = INewsCategoriesItem[];

// Books
interface IBooksAuthorsItem {
  name: string,
  hash: string,
}
export interface IBooksEFilesItem {
  fileId: string;
  fatName: string;
  type: string;
  fileName: string;
}
export interface IBooksAFilesItem {
  fatName: string
  abookFileId: string
  fileId: string
  fileName: string
  part: number
  type: string
}
export interface IBooksGenresItem {
  name: string,
  hash: string,
  subGenres: Omit<IBooksGenresItem, 'subGenres'>
}
export type IBooksGenresData = IBooksGenresItem[];
export interface IBooksDetailData {
  bookId: string,
  abookPreview: string,
  ebookPreview: string,
  abookCardId: string | null,
  ebookCardId: string | null,
  duration: string | null,
  name: string,
  annotation: string | null,
  explicit: string | null,
  authors: IBooksAuthorsItem[],
  genres: Omit<IBooksGenresItem, 'subGenres'>[],
  eFiles: IBooksEFilesItem[],
  aFiles: IBooksAFilesItem[],
}
export type IBooksData = IBooksDetailData[];

// Banners
export interface IBannersItem {
  alias: string,
  description: string,
  image: string,
  link: string,
  name: string,
}
export type IBannersData = IBannersItem[];

// DynamicData
export type IDynamicData =
  null |
  IProfileData |
  IProfilePostData |
  IProfileInterestsData |
  IProfileBookmarksJournalsData |
  IProfileJournalsFiltersData |
  IProfileBookmarksArticlesData |
  IProfileArticlesFiltersData |
  IFaqData |
  IErrorByAliasData |
  IOfferData |
  IAboutData |
  IJournalsData |
  IJournalsIssuesData |
  IProfileHistoryJournalsIssuesData |
  IJournalsIssuesDetailData |
  IJournalsIssuesDetailViewData |
  IJournalsItem |
  IArticlesData |
  IArticlesItem |
  INewsItem |
  INewsData |
  INewsCategoriesData |
  IBooksGenresData |
  IBooksData |
  IBooksDetailData |
  IBannersData |
  IJournalsIssuesArticlesData |
  IArticlesItemData |
  IJournalsCategoriesData |
  ISearchCountData;

export interface IDynamicPayloadCurrentRequest {
  url?: AxiosRequestConfig['url'],
  params?: AxiosRequestConfig['params'],
  data?: AxiosRequestConfig['data'],
}
export interface IDynamicPayloadCurrent {
  lang: Languages | string
  request?: IDynamicPayloadCurrentRequest | null
}
export interface IDynamicPayload<T = any> {
  loading: boolean
  data: T,
  success: boolean,
  error: any,
  failed: boolean,
  current: IDynamicPayloadCurrent | null
}

// ActionTypes
export type IProfileActionTypes =
    IDynamicPayload<IProfileData>['data'] |
    IDynamicPayload<IProfileData>['error'];
export type IProfilePostActionTypes =
    IDynamicPayload<IProfilePostData>['data'] |
    IDynamicPayload<IProfilePostData>['error'];
export type IProfileInterestsActionTypes =
    IDynamicPayload<IProfileInterestsData>['data'] |
    IDynamicPayload<IProfileInterestsData>['error'];
export type IProfileBookmarksJournalsTypes =
    IDynamicPayload<IProfileBookmarksJournalsData>['data'] |
    IDynamicPayload<IProfileBookmarksJournalsData>['error'];
export type IProfileBookmarksArticlesTypes =
    IDynamicPayload<IProfileBookmarksArticlesData>['data'] |
    IDynamicPayload<IProfileBookmarksArticlesData>['error'];
export type IProfileBookmarksJournalsFiltersTypes =
    IDynamicPayload<IProfileJournalsFiltersData>['data'] |
    IDynamicPayload<IProfileJournalsFiltersData>['error'];
export type IProfileBookmarksArticlesFiltersTypes =
    IDynamicPayload<IProfileArticlesFiltersData>['data'] |
    IDynamicPayload<IProfileArticlesFiltersData>['error'];
export type IProfileHistoryJournalsFiltersTypes =
    IDynamicPayload<IProfileJournalsFiltersData>['data'] |
    IDynamicPayload<IProfileJournalsFiltersData>['error'];
export type IProfileHistoryArticlesFiltersTypes =
    IDynamicPayload<IProfileArticlesFiltersData>['data'] |
    IDynamicPayload<IProfileArticlesFiltersData>['error'];

export type IFaqActionTypes =
    IDynamicPayload<IFaqData>['data'] |
    IDynamicPayload<IFaqData>['error'];
export type IOfferActionTypes =
    IDynamicPayload<IOfferData>['data'] |
    IDynamicPayload<IOfferData>['error'];
export type IAboutActionTypes =
    IDynamicPayload<IAboutData>['data'] |
    IDynamicPayload<IAboutData>['error'];
export type IErrorByAliasActionTypes =
    IDynamicPayload<IErrorByAliasData>['data'] |
    IDynamicPayload<IErrorByAliasData>['error'];

export type IJournalsCategoriesActionTypes =
    IDynamicPayload<IJournalsCategoriesData>['data'] |
    IDynamicPayload<IJournalsCategoriesData>['error'];
export type IJournalsActionTypes =
    IDynamicPayload<IJournalsData>['data'] |
    IDynamicPayload<IJournalsData>['error'];
export type IJournalsIssuesActionTypes =
    IDynamicPayload<IJournalsIssuesData>['data'] |
    IDynamicPayload<IJournalsIssuesData>['error'];
export type IProfileHistoryJournalsIssuesActionTypes =
    IDynamicPayload<IProfileHistoryJournalsIssuesData>['data'] |
    IDynamicPayload<IProfileHistoryJournalsIssuesData>['error'];
export type IJournalsIssuesDetailActionTypes =
    IDynamicPayload<IJournalsIssuesDetailData>['data'] |
    IDynamicPayload<IJournalsIssuesDetailData>['error'];
export type IJournalsIssuesDetailViewActionTypes =
    IDynamicPayload<IJournalsIssuesDetailViewData>['data'] |
    IDynamicPayload<IJournalsIssuesDetailViewData>['error'];
export type IJournalsDetailActionTypes =
    IDynamicPayload<IJournalsItem>['data'] |
    IDynamicPayload<IJournalsItem>['error'];
export type IJournalsIssuesArticlesActionTypes =
    IDynamicPayload<IJournalsIssuesArticlesData>['data'] |
    IDynamicPayload<IJournalsIssuesArticlesData>['error'];

export type IArticlesCategoriesActionTypes =
    IDynamicPayload<IArticlesCategoriesData>['data'] |
    IDynamicPayload<IArticlesCategoriesData>['error'];
export type IArticlesActionTypes =
    IDynamicPayload<IArticlesData>['data'] |
    IDynamicPayload<IArticlesData>['error'];
export type IArticlesItemActionTypes =
    IDynamicPayload<IArticlesItemData>['data'] |
    IDynamicPayload<IArticlesItemData>['error'];

export type INewsActionTypes =
    IDynamicPayload<INewsData>['data'] |
    IDynamicPayload<INewsData>['error'];
export type INewsCategoriesActionTypes =
    IDynamicPayload<INewsCategoriesData>['data'] |
    IDynamicPayload<INewsCategoriesData>['error'];
export type INewsItemActionTypes =
    IDynamicPayload<INewsItem>['data'] |
    IDynamicPayload<INewsItem>['error'];

// Books
export type IBooksActionTypes =
  IDynamicPayload<IBooksData>['data'] |
  IDynamicPayload<IBooksData>['error'];

export type IBooksGenresActionTypes =
  IDynamicPayload<IBooksGenresData>['data'] |
  IDynamicPayload<IBooksGenresData>['error'];

export type IBooksDetailActionTypes =
  IDynamicPayload<IBooksDetailData>['data'] |
  IDynamicPayload<IBooksDetailData>['error'];

// Search
export type ISearchJournalsActionTypes =
    IDynamicPayload<IJournalsData>['data'] |
    IDynamicPayload<IJournalsData>['error'];
export type ISearchArticlesActionTypes =
    IDynamicPayload<IArticlesData>['data'] |
    IDynamicPayload<IArticlesData>['error'];
export type ISearchNewsActionTypes =
    IDynamicPayload<INewsData>['data'] |
    IDynamicPayload<INewsData>['error'];
export type ISearchCountActionTypes =
    IDynamicPayload<ISearchCountData>['data'] |
    IDynamicPayload<ISearchCountData>['error'];

// IRootActionTypes
export type IRootActionTypes =
  ILangActionTypes |
  IErrorActionTypes |
  IModalActionTypes |
  IAudioActionTypes |
  IAccessActionTypes |
  IProfileFiltersActionTypes |
  IJournalsCategoriesActionTypes |
  IJournalsActionTypes |
  IJournalsIssuesActionTypes |
  IProfileHistoryJournalsIssuesActionTypes |
  IJournalsIssuesDetailActionTypes |
  IJournalsIssuesDetailViewActionTypes |
  IJournalsDetailActionTypes |
  IJournalsIssuesArticlesActionTypes |
  IArticlesCategoriesActionTypes |
  IArticlesActionTypes |
  IArticlesItemActionTypes |
  INewsActionTypes |
  INewsCategoriesActionTypes |
  INewsItemActionTypes |
  IBooksActionTypes |
  IBooksGenresActionTypes |
  IBooksDetailActionTypes |
  ISearchJournalsActionTypes |
  ISearchArticlesActionTypes |
  ISearchCountActionTypes |
  ISearchNewsActionTypes |
  IFaqActionTypes |
  IOfferActionTypes |
  IAboutActionTypes |
  IErrorByAliasActionTypes |
  IProfilePostActionTypes |
  IProfileInterestsActionTypes |
  IProfileBookmarksJournalsTypes |
  IProfileBookmarksJournalsFiltersTypes |
  IProfileBookmarksArticlesTypes |
  IProfileBookmarksArticlesFiltersTypes |
  IProfileHistoryJournalsFiltersTypes |
  IProfileHistoryArticlesFiltersTypes |
  IProfileActionTypes;

// IRootState
export interface IRootState {
  [States.LANG]: ILangPayload
  [States.MODAL]: IModalPayload
  [States.AUDIO]: IAudioPayload
  [States.ERROR]: IErrorPayload
  [States.ACCESS]: IAccessPayload
  [States.PROFILE]: IDynamicPayload<IProfileData>
  [States.PROFILE_POST]: IDynamicPayload<IProfilePostData>
  [States.PROFILE_FAVORITES]: IDynamicPayload<IJournalsData>
  [States.PROFILE_INTERESTS]: IDynamicPayload<IProfileInterestsData>
  [States.PROFILE_HISTORY_JOURNALS]: IDynamicPayload<IProfileHistoryJournalsIssuesData>
  [States.PROFILE_HISTORY_JOURNALS_FILTERS]: IDynamicPayload<IProfileJournalsFiltersData>
  [States.PROFILE_HISTORY_ARTICLES]: IDynamicPayload<IArticlesData>
  [States.PROFILE_HISTORY_ARTICLES_FILTERS]: IDynamicPayload<IProfileArticlesFiltersData>
  [States.PROFILE_BOOKMARKS_JOURNALS]: IDynamicPayload<IProfileBookmarksJournalsData>
  [States.PROFILE_BOOKMARKS_JOURNALS_FILTERS]: IDynamicPayload<IProfileJournalsFiltersData>
  [States.PROFILE_BOOKMARKS_ARTICLES]: IDynamicPayload<IProfileBookmarksArticlesData>
  [States.PROFILE_BOOKMARKS_ARTICLES_FILTERS]: IDynamicPayload<IProfileArticlesFiltersData>
  [States.PROFILE_FILTERS]: IProfileFiltersPayload
  [States.ERROR_BY_ALIAS]: IDynamicPayload<IErrorByAliasData>
  [States.FAQ]: IDynamicPayload<IFaqData>
  [States.OFFER]: IDynamicPayload<IOfferData>
  [States.ABOUT]: IDynamicPayload<IAboutData>
  [States.JOURNALS]: IDynamicPayload<IJournalsData>
  [States.JOURNALS_CATEGORIES]: IDynamicPayload<IJournalsCategoriesData>
  [States.JOURNALS_NEW]: IDynamicPayload<IJournalsData>
  [States.JOURNALS_RECOMMENDED]: IDynamicPayload<IJournalsData>
  [States.JOURNALS_RECOMMENDED_BY_CATEGORY]: IDynamicPayload<IJournalsData>
  [States.JOURNALS_FREE]: IDynamicPayload<IJournalsData>
  [States.JOURNALS_COLLECTION]: IDynamicPayload<IJournalsData>
  [States.JOURNALS_DETAIL]: IDynamicPayload<IJournalsItem>
  [States.JOURNALS_ISSUES]: IDynamicPayload<IJournalsIssuesData>
  [States.JOURNALS_ISSUES_DETAIL]: IDynamicPayload<IJournalsIssuesDetailData>
  [States.JOURNALS_ISSUES_DETAIL_VIEW]: IDynamicPayload<IJournalsIssuesDetailViewData>
  [States.JOURNALS_ISSUES_ARTICLES]: IDynamicPayload<IJournalsIssuesArticlesData>
  [States.ARTICLES]: IDynamicPayload<IArticlesData>
  [States.ARTICLES_TOP]: IDynamicPayload<IArticlesData>
  [States.ARTICLES_RECOMMENDED]: IDynamicPayload<IArticlesData>
  [States.ARTICLES_NEW]: IDynamicPayload<IArticlesData>
  [States.ARTICLES_LUCKY]: IDynamicPayload<IArticlesItemData>
  [States.ARTICLES_CATEGORIES]: IDynamicPayload<IArticlesCategoriesData>
  [States.ARTICLES_DETAIL]: IDynamicPayload<IArticlesItem>
  [States.NEWS]: IDynamicPayload<INewsData>
  [States.NEWS_RECOMMENDED]: IDynamicPayload<INewsData>
  [States.NEWS_NEW]: IDynamicPayload<INewsData>
  [States.NEWS_DETAIL]: IDynamicPayload<INewsItem>
  [States.NEWS_CATEGORIES]: IDynamicPayload<INewsCategoriesData>
  [States.BOOKS]: IDynamicPayload<IBooksData>
  [States.BOOKS_POPULAR]: IDynamicPayload<IBooksData>
  [States.BOOKS_RECOMMENDED]: IDynamicPayload<IBooksData>
  [States.BOOKS_PARENTS]: IDynamicPayload<IBooksData>
  [States.BOOKS_NEW]: IDynamicPayload<IBooksData>
  [States.BOOKS_SIMILAR]: IDynamicPayload<IBooksData>
  [States.BOOKS_GENRES]: IDynamicPayload<IBooksGenresData>
  [States.BOOKS_DETAIL]: IDynamicPayload<IBooksDetailData>
  [States.BANNERS]: IDynamicPayload<IBannersData>
  [States.SEARCH_BOOKS]: IDynamicPayload<IBooksData>
  [States.SEARCH_BOOKS_COUNT]: IDynamicPayload<ISearchCountData>
  [States.SEARCH_BOOKS_PRE_COUNT]: IDynamicPayload<ISearchCountData>
  [States.SEARCH_JOURNALS]: IDynamicPayload<IJournalsData>
  [States.SEARCH_JOURNALS_COUNT]: IDynamicPayload<ISearchCountData>
  [States.SEARCH_JOURNALS_PRE_COUNT]: IDynamicPayload<ISearchCountData>
  [States.SEARCH_ARTICLES]: IDynamicPayload<IArticlesData>
  [States.SEARCH_ARTICLES_COUNT]: IDynamicPayload<ISearchCountData>
  [States.SEARCH_ARTICLES_PRE_COUNT]: IDynamicPayload<ISearchCountData>
  [States.SEARCH_NEWS]: IDynamicPayload<INewsData>
  [States.SEARCH_NEWS_COUNT]: IDynamicPayload<ISearchCountData>
  [States.SEARCH_NEWS_PRE_COUNT]: IDynamicPayload<ISearchCountData>
}
