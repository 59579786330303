import styled from 'styled-components/macro';
import { ReactComponent as LogoIcon } from 'media/svg/logo.svg';
import Breakpoints from 'themes/constants/breakpoints';
import Inner from 'components/Inner/Inner';

export const LangBannerWrap = styled(Inner)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background: ${({ theme }) => theme.background.primary};
`;
export const LangBannerLogo = styled(LogoIcon)`
  width: 167px;
  height: 60px;
  margin: auto;
  @media only screen and (${Breakpoints.TABLET}) {
    width: 200px;
    height: 80px;
  }
`;
export const LangBannerButtons = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 375px;
  margin-bottom: 30px;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
  @media only screen and (${Breakpoints.TABLET}) {
    margin-bottom: 40px;
    & > *:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;
