import React, { useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import { useHistory } from 'react-router-dom';
import get from 'lodash.get';
import routes from 'routes';
import RoutePath from 'routes/constants/routes';
import { useSelector } from 'react-redux';
import { IRootState } from 'constants/types';
import { States } from 'constants/states';
import moment from 'moment';
import { getCurrentLangAlias } from 'utils/languages';
import useAuth from 'hooks/useAuth';
import useAddToHomeScreen from 'hooks/useAddToHomeScreen';
import useLangBanner from 'hooks/useLangBanner';
import LangBanner from 'components/LangBanner/LangBanner';
import Loader from 'components/Loader/Loader';

moment.updateLocale('ru', {
  invalidDate: 'Нет даты',
  monthsShort: [
    'январь', 'февраль', 'март', 'апрель', 'май', 'июнь',
    'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь',
  ],
});
moment.updateLocale('en', {
  invalidDate: 'No date',
  monthsShort: [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december',
  ],
});
moment.updateLocale('kz', {
  invalidDate: 'Күні жоқ',
  monthsShort: [
    'қаңтар', 'ақпан', 'наурыз', 'сәуір', 'мамыр', 'маусым',
    'шілде', 'тамыз', 'қыркүйек', 'қазан', 'қараша', 'желтоқсан',
  ],
});

const App = () => {
  useAddToHomeScreen();

  const { isLoading: isAuthLoading } = useAuth();

  const { isShow: langBannerShow, handleClose: handleCloseLangBanner } = useLangBanner();

  const history = useHistory();

  const langState = useSelector((state: IRootState) => get(state, States.LANG));
  const langValue = get(langState, 'lang');

  const errorState = useSelector((state: IRootState) => get(state, States.ERROR));
  const errorStatus = get(errorState, ['status'], null);

  useEffect(() => {
    moment.locale(getCurrentLangAlias(langValue));
  }, [langValue]);

  useEffect(() => {
    if (errorStatus) {
      history.replace(RoutePath.ERROR_PATH);
    }
  }, [errorStatus]);

  if (langBannerShow) {
    return (
      <LangBanner handleClose={handleCloseLangBanner} />
    );
  }
  if (isAuthLoading) {
    return <Loader />;
  }
  return renderRoutes(routes);
};

export default App;
