import { ActionTypes } from 'constants/actionTypes';

// Types
export const ACCESS_EDIT = `${ActionTypes.ACCESS}_EDITED`;

export interface IAccessPayload {
  full: boolean;
}

export interface IAccessActionTypes {
  type: string;
  payload: IAccessPayload;
}

const initialState: IAccessPayload = {
  full: false,
};

const accessReducer = (state = initialState, action: IAccessActionTypes): IAccessPayload => {
  switch (action.type) {
    case ACCESS_EDIT:
      return <IAccessPayload>action.payload;
    default:
      return state;
  }
};

export default accessReducer;
