import isNil from 'lodash.isnil';

export const localData = (key: string) => ({
  setValue: (value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  getValue: () => {
    const value = localStorage.getItem(key);
    try {
      if (!isNil(value)) {
        return JSON.parse(value);
      }
      return value;
    } catch (err) {
      return value;
    }
  },
});
