import { APILinks } from 'constants/api';
import { ActionTypes } from 'constants/actionTypes';
import { IActionConfigs } from 'hooks/useFetch';
import { States } from 'constants/states';
import { AxiosRequestConfig } from 'axios';
import { vsprintf } from 'sprintf-js';

interface IJournalsDetailParams {
  categoryId: number,
  journalId: number,
}
interface IJournalsRecommendedByCategoriesParams {
  categoryId: number,
  params: AxiosRequestConfig['params'],
}
interface IJournalsIssuesDetailParams {
  categoryId: number,
  journalId: number,
  issueId: number,
}

export const journalsDetailPromoActionConfigs = {
  apiLink: APILinks.JOURNAL_PROMO,
  actionType: ActionTypes.JOURNALS_DETAIL,
  state: States.JOURNALS_DETAIL,
  unmountClear: true,
};

export const journalsCategoriesActionConfigs: IActionConfigs = {
  apiLink: APILinks.JOURNALS,
  actionType: ActionTypes.JOURNALS_CATEGORIES,
  state: States.JOURNALS_CATEGORIES,
};

export const journalsNewActionConfigs = (data: AxiosRequestConfig['params']): IActionConfigs => ({
  apiLink: `${APILinks.JOURNALS}/0`,
  actionType: ActionTypes.JOURNALS_NEW,
  state: States.JOURNALS_NEW,
  config: { method: 'POST', data },
});

export const journalsRecommendedActionConfigs = (params: AxiosRequestConfig['params']): IActionConfigs => ({
  apiLink: APILinks.JOURNALS_RECOMMENDED,
  actionType: ActionTypes.JOURNALS_RECOMMENDED,
  state: States.JOURNALS_RECOMMENDED,
  config: { params },
});

export const journalsRecommendedByCategoriesIdActionConfigs = ({
  params,
  categoryId,
}: IJournalsRecommendedByCategoriesParams): IActionConfigs => ({
  apiLink: `${APILinks.JOURNALS_TOP}/${categoryId}`,
  actionType: ActionTypes.JOURNALS_RECOMMENDED_BY_CATEGORY,
  state: States.JOURNALS_RECOMMENDED_BY_CATEGORY,
  config: { params },
});

export const journalsFreeActionConfigs = (params: AxiosRequestConfig['params']): IActionConfigs => ({
  apiLink: APILinks.JOURNALS_FREE,
  actionType: ActionTypes.JOURNALS_FREE,
  state: States.JOURNALS_FREE,
  config: { params },
});

export const searchJournalsActionConfigs = (
  { searchQuery, params }: { searchQuery: string, params: AxiosRequestConfig['params'] },
): IActionConfigs => ({
  apiLink: vsprintf(APILinks.SEARCH_JOURNALS, [searchQuery]),
  actionType: ActionTypes.SEARCH_JOURNALS,
  state: States.SEARCH_JOURNALS,
  config: { params },
});

export const searchJournalsCountActionConfigs = (searchQuery: string): IActionConfigs => ({
  apiLink: vsprintf(APILinks.SEARCH_JOURNALS_COUNT, [searchQuery]),
  actionType: ActionTypes.SEARCH_JOURNALS_COUNT,
  state: States.SEARCH_JOURNALS_COUNT,
});

export const searchJournalsPreCountActionConfigs = (searchQuery: string): IActionConfigs => ({
  apiLink: vsprintf(APILinks.SEARCH_JOURNALS_COUNT, [searchQuery]),
  actionType: ActionTypes.SEARCH_JOURNALS_PRE_COUNT,
  state: States.SEARCH_JOURNALS_PRE_COUNT,
});

export const journalsDetailActionConfigs = ({ categoryId, journalId }: IJournalsDetailParams): IActionConfigs => ({
  apiLink: `${APILinks.JOURNALS}/${categoryId}/${journalId}/get`,
  actionType: ActionTypes.JOURNALS_DETAIL,
  state: States.JOURNALS_DETAIL,
  unmountClear: true,
});

export const journalsIssuesDetailActionConfigs = ({
  categoryId,
  journalId,
  issueId,
}: IJournalsIssuesDetailParams): IActionConfigs => ({
  apiLink: `${APILinks.JOURNALS}/${categoryId}/${journalId}/${issueId}/read`,
  actionType: ActionTypes.JOURNALS_ISSUES_DETAIL,
  state: States.JOURNALS_ISSUES_DETAIL,
  unmountClear: true,
});
