import axiosCore, { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import Cookies from 'js-cookie';
import { IDynamicPayload, IDynamicPayloadCurrentRequest } from 'constants/types';
import { errorAction } from 'redux/actions/static/errorActions';
import { getCurrentLangAliasByCookies, getCurrentLangLanding } from 'utils/languages';
import { responseToCamelCase, responseToJSON } from 'utils/response';
import * as DEFAULTS from 'constants/defaults';

export enum AxiosStatus {
  UNAUTHORIZED = 401,
  SUBSCRIPTION_IS_SUSPENDED = 402,
  NO_ACTIVE_SUBSCRIPTION = 403,
  NOT_FOUND = 404,
  SUBSCRIPTION_NOT_FOUND = 409,
  INCORRECT_VALIDATION = 422,
  SUBSCRIPTION_LOCKED = 423,
  SERVER_ERROR = 500,
}

export const SUBSCRIPTIONS_ERRORS = [
  AxiosStatus.SUBSCRIPTION_IS_SUSPENDED,
  AxiosStatus.SUBSCRIPTION_LOCKED,
];

export const SUBSCRIPTIONS_WITH_REDIRECT_ERRORS = [
  AxiosStatus.NO_ACTIVE_SUBSCRIPTION,
  AxiosStatus.SUBSCRIPTION_NOT_FOUND,
];

export const AXIOS_CANCEL_TOKEN_ANCHOR_MESSAGE = 'AXIOS_REQUEST_WAS_FORCIBLY_STOPPED';

export const clearAuthCookies = () => {
  Cookies.remove('token');
};

export const getPayloadFromSuccess = (res: AxiosResponse, currentRequest: IDynamicPayloadCurrentRequest) => {
  const { data } = res;

  const current: IDynamicPayload['current'] = {
    lang: getCurrentLangAliasByCookies(),
    request: currentRequest,
  };

  return { data, current };
};

interface IErrorResponse {
  message?: string
}
export const getPayloadFromError = (er: AxiosError, dispatch: Dispatch) => {
  const lang = getCurrentLangAliasByCookies();

  const response = er?.response;
  const responseStatus = response?.status;
  const config = er?.config;
  const responseType = config?.responseType;
  const responseData: IErrorResponse = (() => {
    if (responseType === 'arraybuffer') {
      const responseBuffer = response?.data as BufferSource;
      const decoder = new TextDecoder('utf-8');
      const decodedString = decoder.decode(responseBuffer);
      return JSON.parse(decodedString);
    }
    return response?.data;
  })();

  const errorMessageResponse = responseData?.message;
  const errorMessageSubscribe = errorMessageResponse || ['errors', 'subscribe'];
  const errorMessageServer = errorMessageResponse || ['errors', 'server'];
  const errorMessageValidation = errorMessageResponse || ['errors', 'incorrectValidation'];
  const errorMessageNotFound = errorMessageResponse || ['words', 'errorText'];

  const errorFrom = window.location.pathname;

  if (responseStatus) {
    if (
      responseStatus === AxiosStatus.UNAUTHORIZED
      || SUBSCRIPTIONS_WITH_REDIRECT_ERRORS.find((item) => item === responseStatus)
    ) {
      clearAuthCookies();
      window.location.replace(getCurrentLangLanding(lang));
    } else if (responseStatus === AxiosStatus.NOT_FOUND) {
      dispatch(errorAction({
        status: responseStatus,
        message: errorMessageNotFound,
        from: errorFrom,
        lang,
      }));
    } else if (SUBSCRIPTIONS_ERRORS.find((item) => item === responseStatus)) {
      dispatch(errorAction({
        status: responseStatus,
        message: errorMessageSubscribe,
        from: errorFrom,
        lang,
      }));
    } else if (responseStatus === AxiosStatus.INCORRECT_VALIDATION) {
      dispatch(errorAction({
        status: responseStatus,
        message: errorMessageValidation,
        from: errorFrom,
        lang,
      }));
    } else if (responseStatus >= AxiosStatus.SERVER_ERROR) {
      dispatch(errorAction({
        status: responseStatus,
        message: errorMessageServer,
        from: errorFrom,
        lang,
      }));
    }
  }

  return Promise.reject(responseData);
};

export enum AxiosTransformType {
  CAMEL_CASE = 'CAMEL_CASE',
  JSON = 'JSON',
  ARRAY_BUFFER = 'ARRAY_BUFFER',
}
export enum AxiosAccept {
  ALL = '*/*',
  JSON = 'application/json',
}

interface IAxiosProps {
  accept?: AxiosAccept
  transformType?: AxiosTransformType
  skipAuth?: boolean
  withCredentials?: boolean
  token?: string | null
}

const axios = (props?: IAxiosProps) => {
  const skipAuth = props?.skipAuth || false;
  const transformType = props?.transformType || AxiosTransformType.CAMEL_CASE;
  const accept = props?.accept || AxiosAccept.JSON;
  const withCredentials = props?.withCredentials ?? true;

  const token = Cookies.get('token') || '';
  const lang = getCurrentLangAliasByCookies();

  if (token && !skipAuth) {
    axiosCore.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    axiosCore.defaults.headers.Authorization = null;
  }
  axiosCore.defaults.headers['Project-Id'] = DEFAULTS.PROJECT_ID;
  axiosCore.defaults.headers.Language = lang;
  axiosCore.defaults.headers.Accept = accept;

  axiosCore.defaults.baseURL = '/';
  axiosCore.defaults.timeout = 100000;
  axiosCore.defaults.withCredentials = withCredentials;

  if (transformType === AxiosTransformType.CAMEL_CASE) {
    axiosCore.defaults.transformResponse = [responseToCamelCase];
  } else if (transformType === AxiosTransformType.JSON) {
    axiosCore.defaults.transformResponse = [responseToJSON];
  } else {
    axiosCore.defaults.transformResponse = undefined;
  }

  return axiosCore;
};

export default axios;
