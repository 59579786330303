import Cookies from 'js-cookie';
import * as DEFAULTS from 'constants/defaults';
import { defaultLanguage, ILangItem, languages } from 'constants/languages';

export const getCurrentLang = (value: ILangItem['alias']): ILangItem => {
  const current: ILangItem | undefined = languages?.find(({ alias }) => alias === value);
  return current || defaultLanguage;
};

export const getCurrentLangAlias = (value: ILangItem['alias']): ILangItem['alias'] => getCurrentLang(value).alias;

export const getCurrentLangLanding = (value: ILangItem['alias']): ILangItem['landing'] => {
  const currentLanguageLanding = getCurrentLang(value).landing;
  return `${currentLanguageLanding}`
    + `&content_url=${encodeURIComponent(window.location.pathname)}`
    + `${encodeURIComponent(window.location.search)}`;
};

export const getCurrentLangAliasByCookies = (): ILangItem['alias'] => {
  const currentLangCookies = Cookies.get('lang') || DEFAULTS.LANG;
  return getCurrentLangAlias(currentLangCookies);
};
