import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import axiosCore, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
} from 'axios';
import axios, { AXIOS_CANCEL_TOKEN_ANCHOR_MESSAGE, getPayloadFromError, getPayloadFromSuccess } from 'utils/axios';
import useDeepEffect from 'hooks/useDeepEffect';
import { IDynamicPayload, IDynamicPayloadCurrentRequest, IRootState } from 'constants/types';
import { ActionTypes } from 'constants/actionTypes';
import { States } from 'constants/states';
import { APILinks } from 'constants/api';
import isEqual from 'lodash.isequal';

export interface IActionConfigs {
  actionType: ActionTypes
  apiLink: APILinks | string
  state: States
  config?: Omit<AxiosRequestConfig, 'url' | 'cancelToken'>
  unmountClear?: boolean
  skipLangDeps?: boolean
  skipError?: boolean
  alwaysRefetch?: boolean
  onSuccess?: (res: AxiosResponse, dispatch: Dispatch) => void
  onFinish?: () => void
  onError?: (er?: AxiosError) => void
}

interface IUseFetchProps {
  actionConfigs: IActionConfigs[]
  dependencies?: any[]
  condition?: boolean
}
interface IUseFetch {
  refetch: () => void
}

const useFetch = (props: IUseFetchProps): IUseFetch => {
  const {
    actionConfigs,
    condition = true,
    dependencies: dependenciesBase = [],
  } = props;

  const dispatch = useDispatch();

  const rootState = useSelector((state: IRootState) => state);

  const langState = useSelector((state: IRootState) => state[States.LANG]);
  const langAlias = langState.lang;

  const deepDependencies = useMemo(() => [condition, langAlias, ...dependenciesBase], [condition, langAlias, dependenciesBase]);

  const fetchFun = (isRefetch: boolean, cancelToken?: CancelToken) => {
    actionConfigs?.forEach((item: IActionConfigs) => {
      const itemStateName = item?.state;
      const itemAlwaysRefetch = item?.alwaysRefetch;
      const itemUnmountClear = item?.unmountClear;
      const itemSkipLangDeps = item?.skipLangDeps;
      const itemState = rootState?.[itemStateName] as IDynamicPayload;
      const itemCurrent = itemState?.current;
      const itemCurrentLang = itemCurrent?.lang;
      const itemCurrentRequest = itemCurrent?.request;
      const itemSuccess = itemState?.success;

      const newCurrentRequest: IDynamicPayloadCurrentRequest = {
        url: item.apiLink,
        params: item.config?.params || null,
        data: item.config?.data || null,
      };

      const needRefetchByRequest = !isEqual(newCurrentRequest, itemCurrentRequest);
      const needRefetchByLang = !isEqual(langAlias, itemCurrentLang) && !itemSkipLangDeps;
      const needRefetch = [
        isRefetch,
        itemAlwaysRefetch,
        itemUnmountClear,
        !itemSuccess,
        needRefetchByRequest,
        needRefetchByLang,
      ].some((bool) => bool);

      if (needRefetch) {
        const {
          apiLink,
          actionType,
          config,
          onSuccess,
          onError,
          onFinish,
          skipError,
        } = item;

        const payload = axios()
          .request({
            ...config,
            url: apiLink,
            cancelToken,
          })
          .then((res) => {
            if (onSuccess) {
              onSuccess(res, dispatch);
            }
            return getPayloadFromSuccess(res, newCurrentRequest);
          })
          .catch((er) => {
            if (!skipError) {
              if (onError) {
                onError(er);
              }
              return getPayloadFromError(er, dispatch);
            }
            return null;
          })
          .finally(() => {
            if (onFinish) {
              onFinish();
            }
          });

        dispatch({
          type: actionType,
          payload,
        });
      }
    });
  };

  useDeepEffect(() => {
    const cancelTokenSource = axiosCore.CancelToken.source();

    if (condition) {
      fetchFun(false, cancelTokenSource.token);
    }

    return () => {
      if (Array.isArray(actionConfigs)) {
        actionConfigs?.forEach(({ actionType, unmountClear }: IActionConfigs) => {
          if (unmountClear) {
            dispatch({
              type: `${actionType}_CLEAR`,
            });
          }
        });
      }
      cancelTokenSource.cancel(AXIOS_CANCEL_TOKEN_ANCHOR_MESSAGE);
    };
  }, deepDependencies);

  const refetch = () => fetchFun(true);

  return { refetch };
};

export default useFetch;
