import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { AxiosResponse } from 'axios';
import pickBy from 'lodash.pickby';
import queryString from 'query-string';
import get from 'lodash.get';
import { APILinks } from 'constants/api';
import axios, { getPayloadFromError } from 'utils/axios';
import * as DEFAULTS from 'constants/defaults';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import mapKeys from 'lodash.mapkeys';
import { defaultLanguage } from 'constants/languages';
import useFetch from 'hooks/useFetch';
import { profileWithoutCatchActionConfigs } from 'redux/actions/profile/profileActions';

interface IUseAuth {
  isLoading: boolean
}

function useAuth(): IUseAuth {
  const history = useHistory();

  const dispatch = useDispatch();

  const search = history.location.search || '';
  const queries = queryString.parse(search);
  const queriesLower = mapKeys(queries, (value, key: string) => `${key}`.toLowerCase());
  const sidQueries = queriesLower?.sid || '';
  const sidQuery: string = Array.isArray(sidQueries) ? (sidQueries[0] || '') : sidQueries;

  const [isSidLoading, setIsSidLoading] = useState<boolean>(true);

  const stopSidLoading = () => setIsSidLoading(false);

  const isAuth = !!Cookies.get('token');

  useEffect(() => {
    Cookies.set('project', DEFAULTS.PROJECT_ID, { expires: 365 * 10 });

    if (!Cookies.get('lang')) {
      Cookies.set('lang', defaultLanguage.alias, { expires: 365 * 10 });
    }
  }, []);

  useFetch({
    actionConfigs: [
      profileWithoutCatchActionConfigs,
    ],
    condition: isAuth && !isSidLoading,
  });

  const fetchSid = () => {
    axios({ skipAuth: true })
      .post(APILinks.BY_SID, { sid: sidQuery })
      .then((res: AxiosResponse) => {
        const token = get(res, ['data', 'apiToken']);

        Cookies.set('sid', sidQuery, { expires: 365 * 10 });
        Cookies.set('token', token, { expires: 365 * 10 });
      })
      .then(() => {
        const searchWithoutSid = pickBy(queries, (value, key) => `${key}`.toLowerCase() !== 'sid');
        history.push({ search: queryString.stringify(searchWithoutSid) });

        stopSidLoading();
      })
      .catch((er) => {
        stopSidLoading();
        return getPayloadFromError(er, dispatch);
      });
  };

  useEffect(() => {
    if (sidQuery) {
      fetchSid();
    } else {
      stopSidLoading();
    }
  }, []);

  return {
    isLoading: isSidLoading,
  };
}

export default useAuth;
