import { APILinks } from 'constants/api';
import { ActionTypes } from 'constants/actionTypes';
import { IActionConfigs } from 'hooks/useFetch';
import { States } from 'constants/states';
import { Dispatch } from 'redux';
import axios, { getPayloadFromError } from 'utils/axios';
import get from 'lodash.get';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  IProfileData,
  IProfilePostData,
  IRootState,
} from 'constants/types';
import { setFullAccessAction } from 'redux/actions/access/accessActions';
import { firebaseMsisdnFetch } from 'utils/firebase';
import { checkIsActiveByState } from 'utils/profile';

export interface IPostProfile {
  name: string
  avatar?: string | null
  msisdn: string | number
}

export const postProfileAction = (data: IPostProfile) => (dispatch: Dispatch, getState: () => IRootState) => {
  const formData = new FormData();

  formData.append('name', get(data, ['name'], ''));
  // @ts-ignore
  formData.append('msisdn', get(data, ['msisdn']));

  if (data.avatar) {
    formData.append('avatar', data.avatar);
  } else if (data.avatar === null) {
    formData.append('avatar', '');
  }

  const payload = axios()
    .post(APILinks.PROFILE, formData)
    .then((res: AxiosResponse<IProfilePostData>) => {
      const store = getState();
      const currState = get(store, States.PROFILE);
      const currData = get(currState, ['data'], {});
      const currCurrent = get(currState, ['current'], null);
      const name = get(res, ['data', 'profile', 'name'], null);

      return dispatch({
        type: `${ActionTypes.PROFILE}_FULFILLED`,
        payload: {
          data: {
            ...currData,
            name,
          },
          current: currCurrent,
        },
      });
    })
    .catch((er) => getPayloadFromError(er, dispatch));

  return dispatch({
    type: ActionTypes.PROFILE_POST,
    payload,
  });
};

const profileActionConfigsOnSuccess = (res: AxiosResponse<IProfileData>, dispatch: Dispatch) => {
  dispatch(setFullAccessAction({ full: checkIsActiveByState(res?.data?.status?.state) }));
  firebaseMsisdnFetch(res?.data?.msisdn);
};

export const profileActionConfigs: IActionConfigs = {
  apiLink: APILinks.PROFILE,
  actionType: ActionTypes.PROFILE,
  state: States.PROFILE,
  alwaysRefetch: true,
  onSuccess: profileActionConfigsOnSuccess,
};

export const profileWithoutCatchActionConfigs: IActionConfigs = {
  apiLink: APILinks.PROFILE,
  actionType: ActionTypes.PROFILE,
  state: States.PROFILE,
  skipLangDeps: true,
  skipError: true,
  onSuccess: profileActionConfigsOnSuccess,
};

export const clearProfileAction = () => (dispatch: Dispatch) => dispatch({
  type: `${ActionTypes.PROFILE}_CLEAR`,
});

export const profileInterestsActionConfigs: IActionConfigs = {
  apiLink: APILinks.INTERESTS,
  actionType: ActionTypes.PROFILE_INTERESTS,
  state: States.PROFILE_INTERESTS,
};

export const profileHistoryJournalsFiltersActionConfigs: IActionConfigs = {
  apiLink: APILinks.HISTORY_JOURNALS_FILTERS,
  actionType: ActionTypes.PROFILE_HISTORY_JOURNALS_FILTERS,
  state: States.PROFILE_HISTORY_JOURNALS_FILTERS,
};

export const profileHistoryArticlesFiltersActionConfigs : IActionConfigs = {
  apiLink: APILinks.HISTORY_ARTICLES_FILTERS,
  actionType: ActionTypes.PROFILE_HISTORY_ARTICLES_FILTERS,
  state: States.PROFILE_HISTORY_ARTICLES_FILTERS,
};

export const profileFavoritesActionConfigs: IActionConfigs = {
  apiLink: APILinks.FAVORITE_JOURNALS,
  actionType: ActionTypes.PROFILE_FAVORITES,
  state: States.PROFILE_FAVORITES,
};

export const profileBookmarksArticlesActionConfigs = (params: AxiosRequestConfig['params']): IActionConfigs => ({
  apiLink: APILinks.BOOKMARKS_ARTICLES,
  actionType: ActionTypes.PROFILE_BOOKMARKS_ARTICLES,
  state: States.PROFILE_BOOKMARKS_ARTICLES,
  config: { method: 'POST', params },
});

export const profileBookmarksJournalsActionConfigs = (params: AxiosRequestConfig['params']): IActionConfigs => ({
  apiLink: APILinks.BOOKMARKS_JOURNALS,
  actionType: ActionTypes.PROFILE_BOOKMARKS_JOURNALS,
  state: States.PROFILE_BOOKMARKS_JOURNALS,
  config: { method: 'POST', params },
});

export const profileBookmarksArticlesFiltersActionConfigs: IActionConfigs = {
  apiLink: APILinks.BOOKMARKS_ARTICLES_FILTERS,
  actionType: ActionTypes.PROFILE_BOOKMARKS_ARTICLES_FILTERS,
  state: States.PROFILE_BOOKMARKS_ARTICLES_FILTERS,
};

export const profileBookmarksJournalsFiltersActionConfigs: IActionConfigs = {
  apiLink: APILinks.BOOKMARKS_JOURNALS_FILTERS,
  actionType: ActionTypes.PROFILE_BOOKMARKS_JOURNALS_FILTERS,
  state: States.PROFILE_BOOKMARKS_JOURNALS_FILTERS,
};
