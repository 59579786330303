import map from 'lodash.map';
import RoutePath from 'routes/constants/routes';
import { States } from 'constants/states';

export enum SearchCategories {
  BOOKS = 'books',
  JOURNALS = 'journals',
  ARTICLES = 'articles',
  ALL = 'all',
  MAIN = '',
}

export interface ISearchCategoriesItem {
  label: string[] | string
  category: SearchCategories
  path: RoutePath
  countState: States | States[] | string | string[]
  preCountState: ISearchCategoriesItem['countState']
}

export const searchCategoriesUniqItems: ISearchCategoriesItem[] = [
  {
    label: ['pages', 'books'],
    category: SearchCategories.BOOKS,
    path: RoutePath.SEARCH_BOOKS_PATH,
    countState: States.SEARCH_BOOKS_COUNT,
    preCountState: States.SEARCH_BOOKS_PRE_COUNT,
  },
  {
    label: ['pages', 'journals'],
    category: SearchCategories.JOURNALS,
    path: RoutePath.SEARCH_JOURNALS_PATH,
    countState: States.SEARCH_JOURNALS_COUNT,
    preCountState: States.SEARCH_JOURNALS_PRE_COUNT,
  },
  {
    label: ['pages', 'articles'],
    category: SearchCategories.ARTICLES,
    path: RoutePath.SEARCH_ARTICLES_PATH,
    countState: States.SEARCH_ARTICLES_COUNT,
    preCountState: States.SEARCH_ARTICLES_PRE_COUNT,
  },
];

export const searchCategoriesAllResultsItem: ISearchCategoriesItem = {
  label: ['words', 'allResults'],
  category: SearchCategories.ALL,
  path: RoutePath.SEARCH_ALL_PATH,
  countState: map(searchCategoriesUniqItems, ({
    countState,
  }) => (!Array.isArray(countState) ? countState : '')),
  preCountState: map(searchCategoriesUniqItems, ({
    preCountState,
  }) => (!Array.isArray(preCountState) ? preCountState : '')),
};
