import * as Cookies from 'js-cookie';
import { LANG_EDITED } from 'redux/reducers/langReducer';
import { ILangItem } from 'constants/languages';

export const setLang = (lang: ILangItem['alias']) => {
  Cookies.set('lang', lang, { expires: 365 * 10 });

  return {
    type: LANG_EDITED,
    payload: lang,
  };
};
