import React, {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  MouseEvent,
} from 'react';
import Breakpoints from 'themes/constants/breakpoints';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as LogoIcon } from 'media/svg/logo.svg';
import RoutePath from 'routes/constants/routes';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash.get';
import SearchField from 'components/Fields/Search/SearchField';
import { IRootState } from 'constants/types';
import Cookies from 'js-cookie';
import { desktopMenu } from 'constants/menu';
import { States } from 'constants/states';
import useTranslate from 'hooks/useTranslate';
import map from 'lodash.map';
import { ILangItem, languages } from 'constants/languages';
import { setLang } from 'redux/actions/static/langActions';
import { getCurrentLangLanding } from 'utils/languages';
import { Form, IFormValues } from 'components/Form/Form';
import { Field } from 'react-final-form';
import useSearchForm from 'hooks/useSearchForm';
import queryString from 'query-string';
import BackButton from 'components/BackButton/BackButton';
import {
  HeaderMainInner,
  HeaderMainLangButton,
  HeaderMainLangButtons,
  HeaderMainLeftSideDesktop,
  HeaderMainLeftSideMobile,
  HeaderMainLoginIcon,
  HeaderMainLogoLink,
  HeaderMainProfileButton,
  HeaderMainRightSideDesktop,
  HeaderMainRightSideMobile,
  HeaderMainSearchButton,
  HeaderMainSearchIcon,
  HeaderMainSwitchers,
  HeaderMainTab,
  HeaderMainTabs,
  HeaderMainTitle,
  HeaderMainUserIcon,
  HeaderMainWrap,
} from './HeaderMainStyles';

export enum HeaderType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}
export interface IHeaderMainProps {
  titlePath?: string | string[]
  type?: HeaderType
  withSearch?: boolean
  withProfile?: boolean
}

// Fields
const SearchFields = () => (
  <Field
    render={(fieldProps) => <SearchField {...fieldProps} />}
    name="search"
    fullWidth
  />
);

// Component
const HeaderMain = forwardRef<HTMLDivElement, IHeaderMainProps>((props, ref) => {
  const {
    titlePath,
    type = HeaderType.PRIMARY,
    withSearch = true,
    withProfile = true,
  } = props;

  const isPrimary = type === HeaderType.PRIMARY;

  const [headerHide, setHeaderHide] = useState<boolean>(false);
  const [lastScrollPosition, setLastScrollPosition] = useState<number>(0);

  const dispatch = useDispatch();

  const [getText] = useTranslate();

  const { handleRedirect } = useSearchForm();

  const history = useHistory();
  const pathname = get(history, ['location', 'pathname'], '');

  const search = get(history, ['location', 'search'], '');
  const queries = queryString.parse(search);
  const searchQuery = get(queries, ['search'], '');
  const searchValue = typeof searchQuery === 'string' ? searchQuery : '';

  const isLaptop = useMediaQuery({ query: `(${Breakpoints.LAPTOP})` });
  const isDesktop = useMediaQuery({ query: `(${Breakpoints.LAPTOP_L})` });

  const handleOpen = () => setHeaderHide(false);
  const handleHide = () => setHeaderHide(true);

  useEffect(() => {
    const scrollListener: EventListener = () => {
      const scrollPosition = document.documentElement.scrollTop;
      const defaultOffset = 200;

      setLastScrollPosition(scrollPosition);

      if (scrollPosition > lastScrollPosition && !headerHide && scrollPosition > defaultOffset) {
        handleHide();
      } else if (scrollPosition < lastScrollPosition && headerHide) {
        handleOpen();
      }
    };

    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [lastScrollPosition]);

  useEffect(() => {
    handleOpen();
  }, [pathname]);

  const isAuth = !!Cookies.get('token');
  const pathnameSplit = pathname.split('/');
  const title = titlePath ? getText(titlePath) : null;

  const langState = useSelector((state: IRootState) => get(state, States.LANG));
  const langValue = get(langState, 'lang');

  const [isOpened, setIsOpened] = useState(false);

  const onLangClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const alias: string | undefined = e?.currentTarget?.dataset?.alias;
    if (alias) {
      const isActive = alias === langValue;
      if (isActive) {
        setIsOpened(!isOpened);
      } else {
        dispatch(setLang(alias));
        setIsOpened(false);
      }
    }
  }, [langValue, isOpened]);

  const handleProfileRedirect = () => {
    if (isAuth) {
      if (history) {
        history.push(RoutePath.PROFILE_PATH);
      }
    } else {
      window.location.replace(getCurrentLangLanding(langValue));
    }
  };

  const searchButton = withSearch ? (
    <HeaderMainSearchButton
      onClick={() => history.push(RoutePath.SEARCH_PATH)}
      isShown={!isOpened}
    >
      <HeaderMainSearchIcon />
    </HeaderMainSearchButton>
  ) : null;

  const logo = (
    <HeaderMainLogoLink to={RoutePath.ROOT_PATH}>
      <LogoIcon />
    </HeaderMainLogoLink>
  );

  const profileButton = withProfile ? (
    <HeaderMainProfileButton
      onClick={handleProfileRedirect}
      isAuth={isAuth}
    >
      {isAuth ? <HeaderMainUserIcon /> : isLaptop ? getText(['words', 'enter']) : <HeaderMainLoginIcon />}
    </HeaderMainProfileButton>
  ) : null;

  const mobileHeaderPrimary = (
    <HeaderMainInner>
      <HeaderMainLeftSideMobile>
        {logo}
      </HeaderMainLeftSideMobile>
      <HeaderMainRightSideMobile>
        {searchButton}
        <HeaderMainSwitchers>
          <HeaderMainLangButtons>
            {map(languages, ({ alias, label: { short: label } }: ILangItem) => (
              (
                <HeaderMainLangButton
                  data-alias={alias}
                  isOpened={isOpened}
                  onClick={onLangClick}
                  isActive={alias === langValue}
                  key={alias}
                >
                  {label}
                </HeaderMainLangButton>
              )
            ))}
          </HeaderMainLangButtons>
        </HeaderMainSwitchers>
        {profileButton}
      </HeaderMainRightSideMobile>
    </HeaderMainInner>
  );

  const mobileHeader = (
    <HeaderMainInner>
      <HeaderMainLeftSideMobile>
        <BackButton />
        <HeaderMainTitle>
          {title}
        </HeaderMainTitle>
      </HeaderMainLeftSideMobile>
      <HeaderMainRightSideMobile>
        {searchButton}
        <HeaderMainSwitchers>
          <HeaderMainLangButtons>
            {map(languages, ({ alias, label: { short: label } }: ILangItem) => (
              (
                <HeaderMainLangButton
                  data-alias={alias}
                  isOpened={isOpened}
                  onClick={onLangClick}
                  isActive={alias === langValue}
                  key={alias}
                >
                  {label}
                </HeaderMainLangButton>
              )
            ))}
          </HeaderMainLangButtons>
        </HeaderMainSwitchers>
        {profileButton}
      </HeaderMainRightSideMobile>
    </HeaderMainInner>
  );

  const initialSearchValues = {
    search: searchValue,
  };
  const handleSearchSubmit = (values: IFormValues) => {
    const value = get(values, ['search'], '');
    handleRedirect({ value });
  };

  const searchFields = withSearch ? (
    <Form
      initialValues={initialSearchValues}
      onSubmit={handleSearchSubmit}
    >
      <SearchFields />
    </Form>
  ) : null;

  const menuTabs = (
    <HeaderMainTabs>
      {desktopMenu.map(({ titlePath: tabTitlePath, path: tabPath }) => {
        // Сравниваю ссылки по первому слову в pathname ...com/СРАВНИВАЕМАЯ_СТРОКА/...
        const tabPathSplit = tabPath.split('/');
        // Беру второй элемент, так как первый всегда равен пустой строке
        const isActive = pathnameSplit?.[1] === tabPathSplit?.[1];

        return (
          <HeaderMainTab
            isActive={isActive}
            onClick={() => history.push(tabPath)}
            key={tabPath}
          >
            {getText(tabTitlePath)}
          </HeaderMainTab>
        );
      })}
    </HeaderMainTabs>
  );

  const langButtons = (
    <HeaderMainLangButtons>
      {map(languages, ({ alias, label: { short: label } }: ILangItem) => (
        <HeaderMainLangButton
          data-alias={alias}
          isOpened
          onClick={onLangClick}
          isActive={alias === langValue}
          key={alias}
        >
          {label}
        </HeaderMainLangButton>
      ))}
    </HeaderMainLangButtons>
  );

  const laptopHeader = (
    <HeaderMainInner>
      <HeaderMainLeftSideDesktop>
        {logo}
      </HeaderMainLeftSideDesktop>
      <HeaderMainRightSideDesktop>
        {langButtons}
        {searchFields}
        {profileButton}
      </HeaderMainRightSideDesktop>
    </HeaderMainInner>
  );

  const desktopHeader = (
    <HeaderMainInner>
      <HeaderMainLeftSideDesktop>
        {logo}
        {menuTabs}
      </HeaderMainLeftSideDesktop>
      <HeaderMainRightSideDesktop>
        {langButtons}
        {searchFields}
        {profileButton}
      </HeaderMainRightSideDesktop>
    </HeaderMainInner>
  );

  const header = (() => {
    if (isDesktop) return desktopHeader;
    if (isLaptop) return laptopHeader;
    if (isPrimary) return mobileHeaderPrimary;
    return mobileHeader;
  })();

  return (
    <HeaderMainWrap
      ref={ref}
      headerHide={headerHide}
    >
      {header}
    </HeaderMainWrap>
  );
});

export default HeaderMain;
