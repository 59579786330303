import { IRootState } from 'constants/types';
import { States } from 'constants/states';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import text from 'languages/data/text.json';
import { getCurrentLangAlias } from 'utils/languages';

// Interfaces
type IUseTranslate = [
  (stringPath: string[] | string) => string,
];

// Hook
const useTranslate = (): IUseTranslate => {
  const langState = useSelector((state: IRootState) => get(state, States.LANG));
  const langValue = get(langState, 'lang');
  const lang = getCurrentLangAlias(langValue);

  const getText = (stringPath: string[] | string) => (typeof stringPath === 'string'
    ? stringPath
    : get(text, ['data', ...stringPath, lang], ['']));

  return [getText];
};

export default useTranslate;
