import {
  DependencyList,
  EffectCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import isEqual from 'lodash.isequal';

type UseEffectReturn = ReturnType<typeof useEffect>;

const checkDeps = (deps: DependencyList) => {
  if (!deps || !deps.length) {
    throw new Error(
      'useDeepEffect should not be used with no dependencies. Use React.useEffect instead.',
    );
  }
};

function useDeepMemoize<T>(value: T) {
  const ref = useRef<T>(value);
  const signalRef = useRef<number>(0);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  return useMemo(() => ref.current, [signalRef.current]);
}

function useDeepEffect(callback: EffectCallback, dependencies: DependencyList): UseEffectReturn {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies);
  }
  return useEffect(callback, useDeepMemoize(dependencies));
}

export default useDeepEffect;
