enum RoutePath {
  ROOT_PATH = '/',
  ACCOUNT_PATH = '/account',
  LOVE_PATH = '/love',
  OFFER_PATH = '/offer',
  FAQ_PATH = '/faq',
  ABOUT_PATH = '/about',
  JOURNAL_PROMO_PATH = '/journal',
  ARTICLE_PROMO_PATH = '/article',
  JOURNALS_PATH = '/journals/categories',
  JOURNALS_CATEGORIES_PATH = '/journals/categories/0',
  JOURNALS_CATEGORIES_BY_ID_PATH = '/journals/categories/%s',
  ARTICLES_PATH = '/articles/categories',
  ARTICLES_CATEGORIES_PATH = '/articles/categories/0',
  ARTICLES_CATEGORIES_BY_ID_PATH = '/articles/categories/%s',
  NEWS_PATH = '/news/categories',
  NEWS_CATEGORIES_PATH = '/news/categories/0',
  NEWS_CATEGORIES_BY_ID_PATH = '/news/categories/%s',
  BOOKS_CATEGORIES_PATH = '/books/categories/0',
  BOOKS_CATEGORIES_BY_ID_PATH = '/books/categories/%s',
  BOOKS_DETAIL_PATH = '/books/detail/%s',
  BOOKS_READ_PATH = '/books/detail/%s/read',
  SEARCH_PATH = '/search',
  SEARCH_ALL_PATH = '/search/all',
  SEARCH_JOURNALS_PATH = '/search/journals',
  SEARCH_ARTICLES_PATH = '/search/articles',
  SEARCH_BOOKS_PATH = '/search/books',
  PROFILE_PATH = '/profile',
  PROFILE_FAVORITES_PATH = '/profile/favorites',
  PROFILE_BOOKMARKS_PATH = '/profile/bookmarks',
  PROFILE_BOOKMARKS_JOURNALS_PATH = '/profile/bookmarks/journals',
  PROFILE_BOOKMARKS_ARTICLES_PATH = '/profile/bookmarks/articles',
  PROFILE_HISTORY_PATH = '/profile/history',
  PROFILE_HISTORY_JOURNALS_PATH = '/profile/history/journals',
  PROFILE_HISTORY_ARTICLES_PATH = '/profile/history/articles',
  PROFILE_INTERESTS_PATH = '/profile/interests',
  NOT_FOUND_PATH = '/not-found',
  ERROR_PATH = '/error',
}

export default RoutePath;
