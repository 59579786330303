import { Languages } from 'constants/types';
import { APILinks } from 'constants/api';

export const LANG = Languages.KZ;
export const LANG_TEXT_SHORT = 'Қз';
export const LANG_TEXT_FULL = 'Қазақша';
export const LANDING = APILinks.AUTH_LINK_KZ;
export const PROJECT_ID: string = '8';

export const PROJECT_TITLE = 'mobi Press';
