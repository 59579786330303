enum Breakpoints {
  MOBILE = 'min-width: 360px',
  MOBILE_M = 'min-width: 375px',
  MOBILE_L = 'min-width: 414px',
  TABLET_ES = 'min-width: 500px',
  TABLET_M = 'min-width: 600px',
  TABLET_S = 'min-width: 660px',
  TABLET = 'min-width: 768px',
  TABLET_L = 'min-width: 900px',
  LAPTOP = 'min-width: 1024px',
  LAPTOP_M = 'min-width: 1100px',
  LAPTOP_L = 'min-width: 1250px',
  LAPTOP_EL = 'min-width: 1300px',
  DESKTOP_S = 'min-width: 1440px',
  DESKTOP = 'min-width: 1700px',
}

export default Breakpoints;
