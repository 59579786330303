import { useHistory } from 'react-router-dom';
import get from 'lodash.get';
import { toLinkFormat } from 'utils/text';
import RoutePath from 'routes/constants/routes';

// Interfaces
interface IHandleRedirectProps {
  value: string
}
interface IUseSearchForm {
  handleRedirect: (props: IHandleRedirectProps) => void
}

// Component
const useSearchForm = (): IUseSearchForm => {
  const history = useHistory();
  const pathname = get(history, ['location', 'pathname'], '');

  const pathnameSliced = `${pathname}`.slice(0, `${RoutePath.SEARCH_PATH}`.length);
  const isSearchPath = pathnameSliced === RoutePath.SEARCH_PATH;
  const isSearchMain = pathname === RoutePath.SEARCH_PATH || pathname === `${RoutePath.SEARCH_PATH}/`;

  const redirectPath = isSearchMain
    ? RoutePath.SEARCH_ALL_PATH
    : isSearchPath
      ? undefined
      : RoutePath.SEARCH_ALL_PATH;

  const handleRedirect = (props: IHandleRedirectProps) => {
    const {
      value,
    } = props;

    const searchLink = toLinkFormat(value);

    if (searchLink) {
      history.replace({
        pathname: redirectPath,
        search: `?search=${searchLink}`,
      });
    }
  };

  return {
    handleRedirect,
  };
};

export default useSearchForm;
