import { ActionTypes } from 'constants/actionTypes';
import { ProfileFiltersAliases } from 'constants/types';
import { profileFiltersObj } from 'constants/profileFilters';

// Interfaces
export type ProfileFiltersOptions = string | string[] | undefined;
export type IProfileFiltersPayload = Record<ProfileFiltersAliases, ProfileFiltersOptions> | {};
export interface IProfileFiltersActionTypes {
  type: string;
  payload: IProfileFiltersPayload;
}

// Init
const initialState: IProfileFiltersPayload = profileFiltersObj;

// Reducer
const profileFiltersReducer = (
  state = initialState,
  action: IProfileFiltersActionTypes,
): IProfileFiltersPayload => {
  switch (action.type) {
    case `${ActionTypes.PROFILE_FILTERS}_INIT`:
      return action.payload;
    case `${ActionTypes.PROFILE_FILTERS}_EDITED`:
      return action.payload;
    default:
      return state;
  }
};

export default profileFiltersReducer;
