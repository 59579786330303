import { AxiosRequestConfig } from 'axios';
import { IActionConfigs } from 'hooks/useFetch';
import { APILinks } from 'constants/api';
import { ActionTypes } from 'constants/actionTypes';
import { States } from 'constants/states';
import { vsprintf } from 'sprintf-js';

interface IJournalsIssuesArticlesParams {
  categoryId: number,
  journalId: number,
  issueId: number,
}
interface IArticlesDetailParams {
  categoryId: number,
  journalId: number,
  issueId: number,
  articleId: number,
}

export const articlesCategoriesActionConfigs: IActionConfigs = {
  apiLink: APILinks.JOURNALS,
  actionType: ActionTypes.ARTICLES_CATEGORIES,
  state: States.ARTICLES_CATEGORIES,
};

export const articlesNewActionConfigs = (params: AxiosRequestConfig['params']): IActionConfigs => ({
  apiLink: APILinks.ARTICLES,
  actionType: ActionTypes.ARTICLES_NEW,
  state: States.ARTICLES_NEW,
  config: { params },
});

export const articlesTopActionConfigs = (params: AxiosRequestConfig['params']): IActionConfigs => ({
  apiLink: APILinks.ARTICLES_TOP,
  actionType: ActionTypes.ARTICLES_TOP,
  state: States.ARTICLES_TOP,
  config: { params },
});

export const searchArticlesActionConfigs = (
  { searchQuery, params }: { searchQuery: string, params: AxiosRequestConfig['params'] },
): IActionConfigs => ({
  apiLink: vsprintf(APILinks.SEARCH_ARTICLES, [searchQuery]),
  actionType: ActionTypes.SEARCH_ARTICLES,
  state: States.SEARCH_ARTICLES,
  config: { params },
});

export const searchArticlesCountActionConfigs = (searchQuery: string): IActionConfigs => ({
  apiLink: vsprintf(APILinks.SEARCH_ARTICLES_COUNT, [searchQuery]),
  actionType: ActionTypes.SEARCH_ARTICLES_COUNT,
  state: States.SEARCH_ARTICLES_COUNT,
});

export const searchArticlesPreCountActionConfigs = (searchQuery: string): IActionConfigs => ({
  apiLink: vsprintf(APILinks.SEARCH_ARTICLES_COUNT, [searchQuery]),
  actionType: ActionTypes.SEARCH_ARTICLES_PRE_COUNT,
  state: States.SEARCH_ARTICLES_PRE_COUNT,
});

export const journalsIssuesArticlesActionConfigs = ({
  categoryId = 0,
  journalId,
  issueId,
}: IJournalsIssuesArticlesParams): IActionConfigs => ({
  apiLink: `${APILinks.JOURNALS}/${categoryId}/${journalId}/${issueId}/articles`,
  actionType: ActionTypes.JOURNALS_ISSUES_ARTICLES,
  state: States.JOURNALS_ISSUES_ARTICLES,
});

export const articlesDetailActionConfigs = ({
  categoryId,
  journalId,
  articleId,
  issueId,
}: IArticlesDetailParams): IActionConfigs => ({
  apiLink: `${APILinks.JOURNALS}/${categoryId}/${journalId}/${issueId}/article/${articleId}`,
  actionType: ActionTypes.ARTICLES_DETAIL,
  state: States.ARTICLES_DETAIL,
});

export const articlesDetailPreviewActionConfigs = ({
  categoryId,
  journalId,
  articleId,
  issueId,
}: IArticlesDetailParams): IActionConfigs => ({
  apiLink: `${APILinks.JOURNALS}/${categoryId}/${journalId}/${issueId}/article/${articleId}/preview`,
  actionType: ActionTypes.ARTICLES_DETAIL,
  state: States.ARTICLES_DETAIL,
});

export const articlesDetailPromoActionConfigs: IActionConfigs = {
  apiLink: APILinks.ARTICLE_PROMO,
  actionType: ActionTypes.ARTICLES_DETAIL,
  state: States.ARTICLES_DETAIL,
};

export const articlesRecommendedActionConfigs = (params: AxiosRequestConfig['params']): IActionConfigs => ({
  apiLink: APILinks.ARTICLES_RECOMMENDED,
  actionType: ActionTypes.ARTICLES_RECOMMENDED,
  state: States.ARTICLES_RECOMMENDED,
  config: { params },
});
