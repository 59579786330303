import styled from 'styled-components/macro';
import Breakpoints from 'themes/constants/breakpoints';

const Inner = styled.div<{ withPaddings?: boolean }>`
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    max-width: ${({ theme }) => theme.width.wrap};
    width: 100%;
    margin: 0 auto;
    ${({ theme, withPaddings }) => withPaddings && theme.paddings.inner.mobile};
    @media only screen and (${Breakpoints.TABLET}) {
        ${({ theme, withPaddings }) => withPaddings && theme.paddings.inner.tablet}
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        ${({ theme, withPaddings }) => withPaddings && theme.paddings.inner.desktop}
    }
`;

export default Inner;
