import reduce from 'lodash.reduce';
import { localData } from 'utils/storage';
import { ProfileFiltersAliases } from 'constants/types';

// Handlers
export const handleGetProfileFiltersLocalName = (alias: ProfileFiltersAliases) => `profileFilters_${alias}`;
const handleGetDataFromLocalStorage = (
  alias: ProfileFiltersAliases,
) => localData(handleGetProfileFiltersLocalName(alias)).getValue();
export const handleGetProfileFiltersItem = (alias: ProfileFiltersAliases) => ({
  alias,
  localValue: handleGetDataFromLocalStorage(alias),
});

// FiltersArr
// Если нужно добавить другие фильтры в профиле, то добавлять поля в массив по ProfileFiltersAliases
export const profileFiltersArr = [
  handleGetProfileFiltersItem(ProfileFiltersAliases.FAVORITES_JOURNALS),
  handleGetProfileFiltersItem(ProfileFiltersAliases.BOOKMARKS_JOURNALS),
  handleGetProfileFiltersItem(ProfileFiltersAliases.BOOKMARKS_ARTICLES),
  handleGetProfileFiltersItem(ProfileFiltersAliases.HISTORY_JOURNALS),
  handleGetProfileFiltersItem(ProfileFiltersAliases.HISTORY_ARTICLES),
];

// Filters
export const profileFiltersObj = reduce(profileFiltersArr, (acc, { alias, localValue }) => ({
  ...acc,
  [alias]: localValue,
}), {});
