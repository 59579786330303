import { ActionTypes } from 'constants/actionTypes';
import { IBooksDetailData } from 'constants/types';
import { toNumber } from 'utils/number';
import { localData } from 'utils/storage';
import { LocalData } from 'constants/storage';

export interface IAudioPayload {
  status: AudioStatuses,
  loading: boolean,
  data: {
    name: IBooksDetailData['name'],
    id: IBooksDetailData['bookId'] | null,
    preview: IBooksDetailData['abookPreview'] | IBooksDetailData['ebookPreview'] | null,
    authors: IBooksDetailData['authors'],
    list: IBooksDetailData['aFiles'],
  },
  current: {
    index: number,
  },
  time: {
    current: number,
    total: number,
    playbackRate: number,
  },
}
export interface IAudioActionTypes {
  type: string;
  payload: any;
}
export enum AudioStatuses {
  PLAY = 'PLAY',
  PAUSE = 'PAUSE',
  STOP = 'STOP',
}

const initialState: IAudioPayload = {
  status: AudioStatuses.STOP,
  loading: false,
  data: {
    name: 'Название неизвестно',
    id: null,
    preview: null,
    authors: [],
    list: [],
  },
  current: {
    index: 0,
  },
  time: {
    current: 0,
    total: 0,
    playbackRate: toNumber(localData(LocalData.AUDIO_PLAYBACK_RATE).getValue(), 1),
  },
};

const audioReducer = (state = initialState, action: IAudioActionTypes): IAudioPayload => {
  const currentBookId = state.data.id || null;
  const payloadBookId = action.payload?.id || null;
  const isCurrentBook = currentBookId && payloadBookId && (currentBookId === payloadBookId);

  switch (action.type) {
    case `${ActionTypes.AUDIO}_STATUS`:
      return {
        ...state,
        status: action.payload,
      };
    case `${ActionTypes.AUDIO}_LOADING`:
      return {
        ...state,
        loading: action.payload,
      };
    case `${ActionTypes.AUDIO}_DATA`:
      return isCurrentBook ? {
        ...state,
        time: {
          ...state.time,
          current: initialState.time.current,
        },
      } : {
        ...state,
        status: AudioStatuses.PAUSE,
        data: action.payload,
      };
    case `${ActionTypes.AUDIO}_CURRENT_TIME`:
      return {
        ...state,
        time: {
          current: action.payload,
          total: state.time.total,
          playbackRate: state.time.playbackRate,
        },
      };
    case `${ActionTypes.AUDIO}_TOTAL_TIME`:
      return {
        ...state,
        time: {
          current: state.time.current,
          total: action.payload,
          playbackRate: state.time.playbackRate,
        },
      };
    case `${ActionTypes.AUDIO}_PLAYBACK_RATE_TIME`:
      return {
        ...state,
        time: {
          current: state.time.current,
          total: state.time.total,
          playbackRate: action.payload,
        },
      };
    case `${ActionTypes.AUDIO}_CURRENT_INDEX`:
      return {
        ...state,
        current: {
          index: action.payload,
        },
      };
    case `${ActionTypes.AUDIO}_CLEAR`:
      return {
        ...initialState,
        time: {
          ...initialState.time,
          playbackRate: state.time.playbackRate,
        },
      };
    default:
      return state;
  }
};

export default audioReducer;
