import { combineReducers } from 'redux';
import langReducer from 'redux/reducers/langReducer';
import { States } from 'constants/states';
import { ActionTypes } from 'constants/actionTypes';
import { IRootState } from 'constants/types';
import createThunkReducer from 'redux/utils/createThunkReducer';
import modalReducer from 'redux/reducers/modalReducer';
import errorReducer from 'redux/reducers/errorReducer';
import accessReducer from 'redux/reducers/accessReducer';
import profileFiltersReducer from 'redux/reducers/profileFiltersReducer';
import audioReducer from 'redux/reducers/audioReducer';

const index = combineReducers<IRootState>({
  [States.LANG]: langReducer,
  [States.MODAL]: modalReducer,
  [States.AUDIO]: audioReducer,
  [States.ERROR]: errorReducer,
  [States.ACCESS]: accessReducer,
  [States.ERROR_BY_ALIAS]: createThunkReducer(ActionTypes.ERROR_BY_ALIAS),
  [States.PROFILE]: createThunkReducer(ActionTypes.PROFILE),
  [States.PROFILE_POST]: createThunkReducer(ActionTypes.PROFILE_POST),
  [States.PROFILE_FAVORITES]: createThunkReducer(ActionTypes.PROFILE_FAVORITES),
  [States.PROFILE_INTERESTS]: createThunkReducer(ActionTypes.PROFILE_INTERESTS),
  [States.PROFILE_HISTORY_JOURNALS]: createThunkReducer(ActionTypes.PROFILE_HISTORY_JOURNALS),
  [States.PROFILE_HISTORY_JOURNALS_FILTERS]: createThunkReducer(ActionTypes.PROFILE_HISTORY_JOURNALS_FILTERS),
  [States.PROFILE_HISTORY_ARTICLES]: createThunkReducer(ActionTypes.PROFILE_HISTORY_ARTICLES),
  [States.PROFILE_HISTORY_ARTICLES_FILTERS]: createThunkReducer(ActionTypes.PROFILE_HISTORY_ARTICLES_FILTERS),
  [States.PROFILE_BOOKMARKS_JOURNALS]: createThunkReducer(ActionTypes.PROFILE_BOOKMARKS_JOURNALS),
  [States.PROFILE_BOOKMARKS_JOURNALS_FILTERS]: createThunkReducer(ActionTypes.PROFILE_BOOKMARKS_JOURNALS_FILTERS),
  [States.PROFILE_BOOKMARKS_ARTICLES]: createThunkReducer(ActionTypes.PROFILE_BOOKMARKS_ARTICLES),
  [States.PROFILE_BOOKMARKS_ARTICLES_FILTERS]: createThunkReducer(ActionTypes.PROFILE_BOOKMARKS_ARTICLES_FILTERS),
  [States.PROFILE_FILTERS]: profileFiltersReducer,
  [States.FAQ]: createThunkReducer(ActionTypes.FAQ),
  [States.OFFER]: createThunkReducer(ActionTypes.OFFER),
  [States.ABOUT]: createThunkReducer(ActionTypes.ABOUT),
  [States.JOURNALS_CATEGORIES]: createThunkReducer(ActionTypes.JOURNALS_CATEGORIES),
  [States.JOURNALS]: createThunkReducer(ActionTypes.JOURNALS),
  [States.JOURNALS_NEW]: createThunkReducer(ActionTypes.JOURNALS_NEW),
  [States.JOURNALS_RECOMMENDED]: createThunkReducer(ActionTypes.JOURNALS_RECOMMENDED),
  [States.JOURNALS_RECOMMENDED_BY_CATEGORY]: createThunkReducer(ActionTypes.JOURNALS_RECOMMENDED_BY_CATEGORY),
  [States.JOURNALS_FREE]: createThunkReducer(ActionTypes.JOURNALS_FREE),
  [States.JOURNALS_COLLECTION]: createThunkReducer(ActionTypes.JOURNALS_COLLECTION),
  [States.JOURNALS_DETAIL]: createThunkReducer(ActionTypes.JOURNALS_DETAIL),
  [States.JOURNALS_ISSUES]: createThunkReducer(ActionTypes.JOURNALS_ISSUES),
  [States.JOURNALS_ISSUES_DETAIL]: createThunkReducer(ActionTypes.JOURNALS_ISSUES_DETAIL),
  [States.JOURNALS_ISSUES_DETAIL_VIEW]: createThunkReducer(ActionTypes.JOURNALS_ISSUES_DETAIL_VIEW),
  [States.JOURNALS_ISSUES_ARTICLES]: createThunkReducer(ActionTypes.JOURNALS_ISSUES_ARTICLES),
  [States.ARTICLES]: createThunkReducer(ActionTypes.ARTICLES),
  [States.ARTICLES_TOP]: createThunkReducer(ActionTypes.ARTICLES_TOP),
  [States.ARTICLES_NEW]: createThunkReducer(ActionTypes.ARTICLES_NEW),
  [States.ARTICLES_LUCKY]: createThunkReducer(ActionTypes.ARTICLES_LUCKY),
  [States.ARTICLES_CATEGORIES]: createThunkReducer(ActionTypes.ARTICLES_CATEGORIES),
  [States.ARTICLES_DETAIL]: createThunkReducer(ActionTypes.ARTICLES_DETAIL),
  [States.ARTICLES_RECOMMENDED]: createThunkReducer(ActionTypes.ARTICLES_RECOMMENDED),
  [States.NEWS]: createThunkReducer(ActionTypes.NEWS),
  [States.NEWS_CATEGORIES]: createThunkReducer(ActionTypes.NEWS_CATEGORIES),
  [States.NEWS_NEW]: createThunkReducer(ActionTypes.NEWS_NEW),
  [States.NEWS_DETAIL]: createThunkReducer(ActionTypes.NEWS_DETAIL),
  [States.NEWS_RECOMMENDED]: createThunkReducer(ActionTypes.NEWS_RECOMMENDED),
  [States.BOOKS]: createThunkReducer(ActionTypes.BOOKS),
  [States.BOOKS_GENRES]: createThunkReducer(ActionTypes.BOOKS_GENRES),
  [States.BOOKS_DETAIL]: createThunkReducer(ActionTypes.BOOKS_DETAIL),
  [States.BOOKS_POPULAR]: createThunkReducer(ActionTypes.BOOKS_POPULAR),
  [States.BOOKS_RECOMMENDED]: createThunkReducer(ActionTypes.BOOKS_RECOMMENDED),
  [States.BOOKS_PARENTS]: createThunkReducer(ActionTypes.BOOKS_PARENTS),
  [States.BOOKS_NEW]: createThunkReducer(ActionTypes.BOOKS_NEW),
  [States.BOOKS_SIMILAR]: createThunkReducer(ActionTypes.BOOKS_SIMILAR),
  [States.BANNERS]: createThunkReducer(ActionTypes.BANNERS),
  [States.SEARCH_BOOKS]: createThunkReducer(ActionTypes.SEARCH_BOOKS),
  [States.SEARCH_BOOKS_COUNT]: createThunkReducer(ActionTypes.SEARCH_BOOKS_COUNT),
  [States.SEARCH_BOOKS_PRE_COUNT]: createThunkReducer(ActionTypes.SEARCH_BOOKS_PRE_COUNT),
  [States.SEARCH_JOURNALS]: createThunkReducer(ActionTypes.SEARCH_JOURNALS),
  [States.SEARCH_JOURNALS_COUNT]: createThunkReducer(ActionTypes.SEARCH_JOURNALS_COUNT),
  [States.SEARCH_JOURNALS_PRE_COUNT]: createThunkReducer(ActionTypes.SEARCH_JOURNALS_PRE_COUNT),
  [States.SEARCH_ARTICLES]: createThunkReducer(ActionTypes.SEARCH_ARTICLES),
  [States.SEARCH_ARTICLES_COUNT]: createThunkReducer(ActionTypes.SEARCH_ARTICLES_COUNT),
  [States.SEARCH_ARTICLES_PRE_COUNT]: createThunkReducer(ActionTypes.SEARCH_ARTICLES_PRE_COUNT),
  [States.SEARCH_NEWS]: createThunkReducer(ActionTypes.SEARCH_NEWS),
  [States.SEARCH_NEWS_COUNT]: createThunkReducer(ActionTypes.SEARCH_NEWS_COUNT),
  [States.SEARCH_NEWS_PRE_COUNT]: createThunkReducer(ActionTypes.SEARCH_NEWS_PRE_COUNT),
});

export default index;
