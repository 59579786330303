export enum ActionTypes {
  PROFILE = 'PROFILE',
  PROFILE_POST = 'PROFILE_POST',
  PROFILE_FAVORITES = 'PROFILE_FAVORITES',
  PROFILE_INTERESTS = 'PROFILE_INTERESTS',
  PROFILE_HISTORY_JOURNALS = 'PROFILE_HISTORY_JOURNALS',
  PROFILE_HISTORY_JOURNALS_FILTERS = 'PROFILE_HISTORY_JOURNALS_FILTERS',
  PROFILE_HISTORY_ARTICLES = 'PROFILE_HISTORY_ARTICLES',
  PROFILE_HISTORY_ARTICLES_FILTERS = 'PROFILE_HISTORY_ARTICLES_FILTERS',
  PROFILE_BOOKMARKS_JOURNALS = 'PROFILE_BOOKMARKS_JOURNALS',
  PROFILE_BOOKMARKS_JOURNALS_FILTERS = 'PROFILE_BOOKMARKS_JOURNALS_FILTERS',
  PROFILE_BOOKMARKS_ARTICLES = 'PROFILE_BOOKMARKS_ARTICLES',
  PROFILE_BOOKMARKS_ARTICLES_FILTERS = 'PROFILE_BOOKMARKS_ARTICLES_FILTERS',
  PROFILE_FILTERS = 'PROFILE_FILTERS',
  FAQ = 'FAQ',
  OFFER = 'OFFER',
  ABOUT = 'ABOUT',
  JOURNALS = 'JOURNALS',
  JOURNALS_CATEGORIES = 'JOURNALS_CATEGORIES',
  JOURNALS_NEW = 'JOURNALS_NEW',
  JOURNALS_RECOMMENDED = 'JOURNALS_RECOMMENDED',
  JOURNALS_RECOMMENDED_BY_CATEGORY = 'JOURNALS_RECOMMENDED_BY_CATEGORY',
  JOURNALS_FREE = 'JOURNALS_FREE',
  JOURNALS_COLLECTION = 'JOURNALS_COLLECTION',
  JOURNALS_DETAIL = 'JOURNALS_DETAIL',
  JOURNALS_ISSUES_DETAIL = 'JOURNALS_ISSUES_DETAIL',
  JOURNALS_ISSUES_DETAIL_VIEW = 'JOURNALS_ISSUES_DETAIL_VIEW',
  JOURNALS_ISSUES = 'JOURNALS_ISSUES',
  JOURNALS_ISSUES_ARTICLES = 'JOURNALS_ISSUES_ARTICLES',
  ARTICLES = 'ARTICLES',
  ARTICLES_DETAIL = 'ARTICLES_DETAIL',
  ARTICLES_CATEGORIES = 'ARTICLES_CATEGORIES',
  ARTICLES_TOP = 'ARTICLES_TOP',
  ARTICLES_NEW = 'ARTICLES_NEW',
  ARTICLES_LUCKY = 'ARTICLES_LUCKY',
  ARTICLES_RECOMMENDED = 'ARTICLES_RECOMMENDED',
  ACCESS = 'ACCESS',
  NEWS = 'NEWS',
  NEWS_CATEGORIES = 'NEWS_CATEGORIES',
  NEWS_NEW = 'NEWS_NEW',
  NEWS_DETAIL = 'NEWS_DETAIL',
  NEWS_RECOMMENDED = 'NEWS_RECOMMENDED',
  BOOKS = 'BOOKS',
  BOOKS_GENRES = 'BOOKS_GENRES',
  BOOKS_DETAIL = 'BOOKS_DETAIL',
  BOOKS_POPULAR = 'BOOKS_POPULAR',
  BOOKS_RECOMMENDED = 'BOOKS_RECOMMENDED',
  BOOKS_PARENTS = 'BOOKS_PARENTS',
  BOOKS_NEW = 'BOOKS_NEW',
  BOOKS_SIMILAR = 'BOOKS_SIMILAR',
  BANNERS = 'BANNERS',
  SEARCH_BOOKS = 'SEARCH_BOOKS',
  SEARCH_BOOKS_COUNT = 'SEARCH_BOOKS_COUNT',
  SEARCH_BOOKS_PRE_COUNT = 'SEARCH_BOOKS_PRE_COUNT',
  SEARCH_JOURNALS = 'SEARCH_JOURNALS',
  SEARCH_JOURNALS_COUNT = 'SEARCH_JOURNALS_COUNT',
  SEARCH_JOURNALS_PRE_COUNT = 'SEARCH_JOURNALS_PRE_COUNT',
  SEARCH_ARTICLES = 'SEARCH_ARTICLES',
  SEARCH_ARTICLES_COUNT = 'SEARCH_ARTICLES_COUNT',
  SEARCH_ARTICLES_PRE_COUNT = 'SEARCH_ARTICLES_PRE_COUNT',
  SEARCH_NEWS = 'SEARCH_NEWS',
  SEARCH_NEWS_COUNT = 'SEARCH_NEWS_COUNT',
  SEARCH_NEWS_PRE_COUNT = 'SEARCH_NEWS_PRE_COUNT',
  LANG = 'LANG',
  MODAL = 'MODAL',
  AUDIO = 'AUDIO',
  ERROR = 'ERROR',
  ERROR_BY_ALIAS = 'ERROR_BY_ALIAS',
}
