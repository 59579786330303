import { IActionConfigs } from 'hooks/useFetch';
import { APILinks } from 'constants/api';
import { ActionTypes } from 'constants/actionTypes';
import { States } from 'constants/states';
import { vsprintf } from 'sprintf-js';
import { AxiosRequestConfig } from 'axios';
import { BooksCollectionHash } from 'constants/books';
import { IBooksDetailData } from 'constants/types';

interface IBooksDetailArg {
  bookId: string
}

export const booksGenresActionConfigs: IActionConfigs = {
  apiLink: APILinks.BOOKS_GENRES,
  actionType: ActionTypes.BOOKS_GENRES,
  state: States.BOOKS_GENRES,
};

export const booksDetailActionConfigs = ({ bookId }: IBooksDetailArg): IActionConfigs => ({
  apiLink: vsprintf(APILinks.BOOKS_DETAIL, [bookId]),
  actionType: ActionTypes.BOOKS_DETAIL,
  state: States.BOOKS_DETAIL,
});

export const booksPopularActionConfigs = (params: AxiosRequestConfig['params']): IActionConfigs => ({
  apiLink: vsprintf(APILinks.BOOKS_COLLECTION, [BooksCollectionHash.POPULAR]),
  actionType: ActionTypes.BOOKS_POPULAR,
  state: States.BOOKS_POPULAR,
  config: { params },
});

export const booksRecommendedActionConfigs = (params: AxiosRequestConfig['params']): IActionConfigs => ({
  apiLink: vsprintf(APILinks.BOOKS_COLLECTION, [BooksCollectionHash.RECOMMENDED]),
  actionType: ActionTypes.BOOKS_RECOMMENDED,
  state: States.BOOKS_RECOMMENDED,
  config: { params },
});

export const booksParentsActionConfigs = (params: AxiosRequestConfig['params']): IActionConfigs => ({
  apiLink: vsprintf(APILinks.BOOKS_COLLECTION, [BooksCollectionHash.PARENTS]),
  actionType: ActionTypes.BOOKS_PARENTS,
  state: States.BOOKS_PARENTS,
  config: { params },
});

export const booksNewActionConfigs = (params: AxiosRequestConfig['params']): IActionConfigs => ({
  apiLink: vsprintf(APILinks.BOOKS_COLLECTION, [BooksCollectionHash.NEW]),
  actionType: ActionTypes.BOOKS_NEW,
  state: States.BOOKS_NEW,
  config: { params },
});

export const booksSimilarActionConfigs = (bookId: IBooksDetailData['bookId']): IActionConfigs => ({
  apiLink: vsprintf(APILinks.BOOKS_SIMILAR, [bookId]),
  actionType: ActionTypes.BOOKS_SIMILAR,
  state: States.BOOKS_SIMILAR,
});

export const searchBooksActionConfigs = (
  { searchQuery, params }: { searchQuery: string, params: AxiosRequestConfig['params'] },
): IActionConfigs => ({
  apiLink: vsprintf(APILinks.SEARCH_BOOKS, [searchQuery]),
  actionType: ActionTypes.SEARCH_BOOKS,
  state: States.SEARCH_BOOKS,
  config: { params },
});

export const searchBooksCountActionConfigs = (searchQuery: string): IActionConfigs => ({
  apiLink: vsprintf(APILinks.SEARCH_BOOKS_COUNT, [searchQuery]),
  actionType: ActionTypes.SEARCH_BOOKS_COUNT,
  state: States.SEARCH_BOOKS_COUNT,
});

export const searchBooksPreCountActionConfigs = (searchQuery: string): IActionConfigs => ({
  apiLink: vsprintf(APILinks.SEARCH_BOOKS_COUNT, [searchQuery]),
  actionType: ActionTypes.SEARCH_BOOKS_PRE_COUNT,
  state: States.SEARCH_BOOKS_PRE_COUNT,
});
