import React, {
  FunctionComponent,
  PropsWithChildren,
  SVGProps,
  MouseEvent,
} from 'react';
import styled from 'styled-components/macro';
import Breakpoints from 'themes/constants/breakpoints';

const ButtonContainer = styled.button<{
  fullWidth? : boolean,
  themeType: ButtonThemeType,
  disabled? : boolean,
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  padding: 11px 25px;
  background: ${({ theme, themeType }) => theme.background.button[themeType]};
  border: ${({ theme, themeType }) => `2px solid ${theme.borderColor.button[themeType]}`};
  border-radius: ${({ theme }) => theme.borderRadius.button};
  width: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '335px')};
  height: 48px;
  max-height: 48px;
  gap: 12px;
  cursor: pointer;
  &, & svg {
    color: ${({ theme, themeType }) => theme.color.button[themeType]};
  }
  &:disabled {
    background: ${({ theme }) => theme.palette.grey};
    color: ${({ theme }) => theme.palette.white};
    cursor: not-allowed;
  }
  @media only screen and (${Breakpoints.TABLET}) {
    width: 100%;
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '380px')};
  }
  @media only screen and (${Breakpoints.LAPTOP}) {
    width: 100%;
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '540px')};
  }
`;

export enum ButtonTypes {
  SUBMIT = 'submit',
  BUTTON = 'button',
}
export enum ButtonThemeType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  MINOR = 'MINOR',
}
type IButtonProps = PropsWithChildren<{
  type?: ButtonTypes
  className?: string
  disabled?: boolean
  fullWidth?: boolean
  themeType?: ButtonThemeType
  onClick?: (ev: MouseEvent<HTMLButtonElement>) => void
  icon?: FunctionComponent<SVGProps<SVGSVGElement>> | null
}>;

const Button = (props: IButtonProps) => {
  const {
    type = ButtonTypes.BUTTON,
    themeType = ButtonThemeType.PRIMARY,
    icon: Icon,
    onClick,
    fullWidth = false,
    disabled = false,
    children,
    className,
  } = props;

  return (
    <ButtonContainer
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={onClick}
      type={type}
      themeType={themeType}
      className={className}
    >
      {Icon ? <Icon /> : null}
      {children}
    </ButtonContainer>
  );
};

export default Button;
