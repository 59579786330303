export enum APILinks {
  FIREBASE_TOKEN = '/firebase/token',
  BY_SID = 'api/auth/by-sid',
  LOGOUT = 'api/auth/logout',
  AUTH_GET_CODE = 'api/auth/get-code',
  AUTH_SIGN_IN = 'api/auth/sign-in',
  PROFILE = 'api/profile',
  PROFILE_SUBSCRIPTION = 'api/profile/subscription',
  PROFILE_UNSUBSCRIBE = 'api/profile/unsubscribe',
  FAQ = 'gate/backinfo/faq',
  OFFER = 'gate/backinfo/agreement',
  ABOUT = 'gate/backinfo/about',
  ERROR_BY_ALIAS = 'gate/backinfo/%s',
  FAVORITE_JOURNALS = 'api/profile/favorite',
  HISTORY_JOURNALS = 'api/profile/journal-history',
  HISTORY_JOURNALS_FILTERS = 'api/profile/journal-history/journal',
  HISTORY_ARTICLES = 'api/profile/article-history',
  HISTORY_ARTICLES_FILTERS = 'api/profile/article-history/journal',
  BOOKMARKS_JOURNALS = 'api/profile/bookmark/journal-page/tree-struct',
  BOOKMARKS_JOURNALS_FILTERS = 'api/profile/bookmark/journal-page/journal',
  BOOKMARKS_ARTICLES = 'api/profile/bookmark/article-list',
  BOOKMARKS_ARTICLES_FILTERS = 'api/profile/bookmark/all-user-articles',
  BOOKMARKS_ISSUES_PAGE_TOGGLE = 'api/profile/bookmark/journal-page',
  BOOKMARKS_ARTICLES_TOGGLE = 'api/profile/bookmark/article',
  INTERESTS = 'api/profile/interests',
  JOURNALS = 'api/journal/categories',
  JOURNAL_PROMO = 'api/issue/promo',
  JOURNALS_FREE = 'api/journal/categories/999',
  JOURNALS_RECOMMENDED = 'api/journal/recommended',
  JOURNALS_TOP = 'api/journal/topJournals',
  ARTICLES_TOP = 'api/topArticles',
  ARTICLES_RECOMMENDED = 'api/topArticles',
  ARTICLES = 'api/articles',
  ARTICLES_LUCKY = 'api/articles/get-lucky',
  ARTICLE_PROMO = 'api/articles/promo',
  NEWS = 'api/news/categories',
  NEWS_RECOMMENDED = 'api/news/categories/0',
  BOOKS = 'api/book/genres/filter',
  BOOKS_GENRES = 'api/book/genres',
  BOOKS_DETAIL = 'api/book/%s',
  BOOKS_CONTENT = 'api/book/content/%s.%s',
  BOOKS_COLLECTION = 'api/book/top/%s',
  BOOKS_SIMILAR = 'api/book/%s/recommended',
  BANNERS = 'api/banner',
  SEARCH_JOURNALS = 'api/search/journal/%s',
  SEARCH_JOURNALS_COUNT = 'api/search/journal/%s/get-count',
  SEARCH_ARTICLES = 'api/search/articles/%s',
  SEARCH_ARTICLES_COUNT = 'api/search/articles/%s/get-count',
  // Поиск пока только по ebooks, согласовано с Максом С
  SEARCH_BOOKS = 'api/search/book/ebook/%s',
  SEARCH_BOOKS_COUNT = 'api/search/book/ebook/%s/get-count',
  AUTH_LINK_RU = 'http://mobipress.kz/lp/flow/kz-kcell-press?lpid=10&source=service',
  AUTH_LINK_EN = 'http://mobipress.kz/lp/flow/kz-kcell-press?lpid=12&source=service',
  AUTH_LINK_KZ = 'http://mobipress.kz/lp/flow/kz-kcell-press?lpid=11&source=service',
  APPS_LINK = 'intent://mobipress.kz/#Intent;scheme=https;package=kz.mobipress;end',
  GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=kz.mobipress',
  APPLE_STORE_LINK = 'https://apps.apple.com/ru/app/mobi-press/id1248360900',
}
