import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import { FormApi, SubmissionErrors } from 'final-form';

export type IFormValues = Record<string, any>;
type IFormInterface = PropsWithChildren<{
  initialValues?: IFormValues
  className?: string
  onSubmit(
    values: IFormValues,
    form: FormApi<IFormValues, IFormValues>,
    callback?: (errors?: SubmissionErrors) =>
    | SubmissionErrors
    | Promise<SubmissionErrors>
    | void
  ): void;
}>;

export const Form = (props: IFormInterface): ReactElement => {
  const {
    initialValues = {},
    onSubmit,
    children,
    className,
  } = props;

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={(renderProps: FormRenderProps): ReactNode => {
        const { handleSubmit } = renderProps;
        return (
          <form
            onSubmit={handleSubmit}
            className={className}
          >
            {children}
          </form>
        );
      }}
    />
  );
};

Form.defaultProps = {
  initialValues: {},
  className: '',
};
