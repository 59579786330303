import { useState } from 'react';
import { localData } from 'utils/storage';
import { useMediaQuery } from 'react-responsive';
import Breakpoints from 'themes/constants/breakpoints';

export interface IUseLangBanner {
  isShow: boolean
  handleClose: () => void
}

const storageKey = 'langBannerShowed';

function useLangBanner(): IUseLangBanner {
  const isLaptop = useMediaQuery({ query: `(${Breakpoints.LAPTOP})` });

  const isShowed = !!localData(storageKey).getValue();

  const [show, setShow] = useState<boolean>(!isShowed);

  const isShow = show && !isLaptop;

  const handleClose = () => {
    setShow(false);
    localData(storageKey).setValue(true);
  };

  return { isShow, handleClose };
}

export default useLangBanner;
