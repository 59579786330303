import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Breakpoints from 'themes/constants/breakpoints';
import { ReactComponent as SearchIconSVG } from 'media/icons/search.svg';
import { ReactComponent as LoginIconSVG } from 'media/icons/arrow-circle-right.svg';
import { ReactComponent as UserIconSVG } from 'media/icons/userpic.svg';

export const HeaderMainWrap = styled.header<{ headerHide?: boolean }>`
  position: sticky;
  top: 0;
  flex-shrink: 0;
  background: ${({ theme }) => theme.background.header};
  color: ${({ theme }) => theme.color.header};
  height: ${({ theme }) => theme.height.header.mobile};
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
  transform: ${({ headerHide }) => headerHide && 'translateY(calc(-100% - 5px))'};
  transition: transform .2s ease-out;
  z-index: 10000;
  @media only screen and (${Breakpoints.TABLET}) {
    height: ${({ theme }) => theme.height.header.tablet};
  }
  @media only screen and (${Breakpoints.LAPTOP}) {
    height: ${({ theme }) => theme.height.header.desktop};
  }
`;
export const HeaderMainInner = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.width.wrap};
  margin: 0 auto;
  & > * {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (${Breakpoints.LAPTOP_L}) {
    ${({ theme }) => theme.paddings.inner.desktop};
    & > *:not(:last-child) {
      margin-right: 20px;
    }
  }
`;
export const HeaderMainLeftSideDesktop = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  @media only screen and (${Breakpoints.LAPTOP}) {
    padding-left: ${({ theme }) => theme.paddings.inner.desktop.paddingLeft};
  }
  @media only screen and (${Breakpoints.LAPTOP_L}) {
    flex-grow: 1;
  }
`;
export const HeaderMainRightSideDesktop = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  & > *:not(:last-child) {
    margin-right: 20px;
  }
  @media only screen and (${Breakpoints.LAPTOP}) {
    padding-right: ${({ theme }) => theme.paddings.inner.desktop.paddingRight};
  }
  @media only screen and (${Breakpoints.LAPTOP_L}) {
    flex-grow: 0;
  }
`;
export const HeaderMainLogoLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 10px;
  & > svg {
    width: auto;
    height: 28px;
  }
  @media only screen and (${Breakpoints.TABLET}) {
    & > svg {
      height: 35px;
    }
  }
  @media only screen and (${Breakpoints.LAPTOP}) {
    & > svg {
      height: 43px;
    }
  }
`;
export const HeaderMainSearchIcon = styled(SearchIconSVG)`
  width: 17px;
  height: 18px;
  color: #6F6F6F;
  @media only screen and (${Breakpoints.TABLET}) {
    width: 24px;
    height: 25px;
  }
`;
export const HeaderMainLoginIcon = styled(LoginIconSVG)`
  width: 24px;
  height: 24px;
  @media only screen and (${Breakpoints.TABLET}) {
    width: 30px;
    height: 30px;
  }
`;
export const HeaderMainUserIcon = styled(UserIconSVG)`
  width: 24px;
  height: 24px;
  @media only screen and (${Breakpoints.TABLET}) {
    width: 30px;
    height: 30px;
  }
`;
export const HeaderMainSideMobile = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  width: ${({ theme }) => `calc(${theme.paddings.inner.tablet.paddingLeft}) + 60px`};
  height: 100%;
`;
export const HeaderMainLeftSideMobile = styled(HeaderMainSideMobile)`
  left: 20px;
  width: 55%;
  justify-content: flex-start;
`;
export const HeaderMainRightSideMobile = styled(HeaderMainSideMobile)`
  right: 20px;
  justify-content: flex-end;
  & > *:not(:last-child) {
    margin-right: 15px;
  }
   @media only screen and (${Breakpoints.TABLET}) {
     & > *:not(:last-child) {
       margin-right: 20px;
     }
   }
`;
export const HeaderMainTabs = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-width: 270px;
  margin: 0 auto;
  & > *:not(:last-child) {
    margin-right: 35px;
  }
`;
export const HeaderMainTab = styled.button<{ isActive?: boolean }>`
  position: relative;
  padding: 3px 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: ${({ theme, isActive }) => (isActive ? theme.palette.primary : '#6f6f6f')};
  cursor: pointer;
  &:hover {
    color: ${({ theme, isActive }) => !isActive && theme.color.secondary};
  }
  @media only screen and (${Breakpoints.TABLET}) {
    &:first-letter {
      text-transform: uppercase;
    }
  }
    @media only screen and (${Breakpoints.TABLET}) {
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;
export const HeaderMainLangButtons = styled.label`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  user-select: none;
  height: 100%;
`;
export const HeaderMainLangButton = styled.div<{ isActive?: boolean, isOpened: boolean }>`
  display: ${({ isOpened, isActive }) => ((isActive || isOpened) ? 'flex' : 'none')};
  margin-right: ${({ isOpened, isActive }) => ((!isOpened) ? '0' : isActive ? '0' : '10px')};
  order: ${({ isActive }) => (isActive ? '999' : '0')};
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 1.2;
  padding: 4px 2px 2px;
  width: 22px;
  height: 20px;
  color: ${({ theme, isActive }) => (isActive ? theme.palette.white : '#6f6f6f')};
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  background-color: ${({ theme, isActive }) => (isActive ? theme.palette.primary : 'transparent')};
  @media only screen and (${Breakpoints.TABLET}) {
    order: 1;
    margin-right: 10px;
   }
  @media only screen and (${Breakpoints.LAPTOP}) {
    &:hover {
      color: ${({ theme }) => (theme.palette.white)};
      background-color: ${({ theme, isActive }) => (!isActive && theme.palette.secondary)};
    }
   }
`;
export const HeaderMainSearchButton = styled.button<{ isShown?: boolean }>`
  display: ${({ isShown }) => (isShown ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  height: 100%;
`;
export const HeaderMainProfileButton = styled.button<{ isAuth?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 1;
  @media only screen and (${Breakpoints.LAPTOP}) {
    background: ${({ theme, isAuth }) => (isAuth ? 'transparent' : theme.palette.primary)};
    color: ${({ theme }) => theme.color.button.signIn};
    padding: ${({ isAuth }) => (isAuth ? 0 : '11px 20px')};
    border-radius: ${({ theme }) => theme.borderRadius.button};
    padding: ${({ isAuth }) => (isAuth ? 0 : '0 27px')};
    width: ${({ isAuth }) => (isAuth ? '40px' : 'auto')};
    height: 40px;
    max-height: 40px;
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
    box-shadow: none !important;
  }
  @media only screen and (${Breakpoints.LAPTOP_L}) {
    margin-right: 0;
  }
`;
export const HeaderMainSwitchers = styled.div`
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  @media only screen and (${Breakpoints.LAPTOP}) {
    margin-top: auto;
    padding-top: 20px;
  }
`;
export const HeaderMainTitle = styled.div`
  margin-left: 12px;
  color: ${({ theme }) => theme.palette.black};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
