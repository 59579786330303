import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  useState,
  useEffect,
  useRef,
} from 'react';
import { ReactComponent as SearchSubmitSVG } from 'media/icons/search-submit.svg';
import { ReactComponent as SearchClearSVG } from 'media/icons/search-clear.svg';
import get from 'lodash.get';
import useFetch from 'hooks/useFetch';
import { searchJournalsPreCountActionConfigs } from 'redux/actions/journals/journalsActions';
import { searchArticlesPreCountActionConfigs } from 'redux/actions/articles/articlesActions';
import { searchBooksPreCountActionConfigs } from 'redux/actions/books/booksActions';
import useDebounce from 'hooks/useDebounce';
import SearchCategoriesDropdown from 'components/Search/categories/dropdown/SearchCategoriesDropdown';
import { toLinkFormat } from 'utils/text';
import useTranslate from 'hooks/useTranslate';
import {
  SearchFieldClearButton,
  SearchFieldInput,
  SearchFieldSearchIcon,
  SearchFieldSubmitButton,
  SearchFieldWrap,
} from './SearchFieldStyles';

interface ISearchField {
  type?: string
  placeholder?: string
  className?: string
  inputMode?: 'text' | 'none' | 'search' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | undefined
  fullWidth?: boolean
  required?: boolean
  input?: any
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void
}

const SearchField = (props: ISearchField): ReactElement => {
  const {
    type = 'text',
    placeholder,
    input,
    className,
    inputMode,
    fullWidth,
    required,
    onChange,
    ...rest
  } = props;

  const [isOpenDropdown, setIisOpenDropdown] = useState<boolean>(false);

  const value = get(input, ['value'], '');
  const debouncedValue = useDebounce(value, 1000);
  const clearValue = `${value}`.trim();
  const clearDebounceValue = `${debouncedValue}`.trim();

  const showDropdown = !!(isOpenDropdown && clearDebounceValue && clearValue);

  const [getText] = useTranslate();

  useFetch({
    actionConfigs: [
      searchBooksPreCountActionConfigs(toLinkFormat(debouncedValue)),
      searchJournalsPreCountActionConfigs(toLinkFormat(debouncedValue)),
      searchArticlesPreCountActionConfigs(toLinkFormat(debouncedValue)),
    ],
    dependencies: [debouncedValue, isOpenDropdown],
    condition: showDropdown,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const handleDropdownOpen = () => setIisOpenDropdown(true);
  const handleDropdownClose = () => setIisOpenDropdown(false);

  const handleSubmit = () => {
    if (inputRef && inputRef.current && inputRef.current.form) {
      inputRef.current.form.requestSubmit();
    }
  };

  const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      handleDropdownClose();
    }
  };
  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    handleDropdownOpen();
    if (input && input.onChange) {
      input.onChange(ev);
    }
    if (onChange) {
      onChange(ev);
    }
  };
  const handleClear = () => {
    if (input && input.onChange) {
      input.onChange('');
    }
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (inputRef.current) {
        if (isOpenDropdown && !inputRef.current.contains(event.target)) {
          handleDropdownClose();
        }
      }
    };
    window.addEventListener('click', listener);
    return () => window.removeEventListener('click', listener);
  }, [isOpenDropdown]);

  const submitButton = (
    <SearchFieldSubmitButton
      onClick={handleSubmit}
    >
      <SearchSubmitSVG />
    </SearchFieldSubmitButton>
  );
  const clearButton = (
    <SearchFieldClearButton
      onClick={handleClear}
    >
      <span>
        <SearchClearSVG />
      </span>
    </SearchFieldClearButton>
  );

  return (
    <SearchFieldWrap fullWidth={fullWidth}>
      <SearchFieldSearchIcon />
      <SearchFieldInput
        {...input}
        {...rest}
        autoComplete="off"
        isOpenDropdown={showDropdown}
        type={type}
        value={value}
        inputMode={inputMode}
        placeholder={placeholder || `${getText(['words', 'search'])}...`}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onClick={handleDropdownOpen}
        onFocus={handleDropdownOpen}
        ref={inputRef}
        required={required}
        maxLength={500}
      />
      {(showDropdown) ? (
        <SearchCategoriesDropdown
          value={`${value}`}
          isPreCount
        />
      ) : null}
      {value ? (
        isOpenDropdown
          ? submitButton
          : clearButton
      ) : null}
    </SearchFieldWrap>
  );
};

export default SearchField;
