import React from 'react';
import useSearchCategories from 'hooks/useSearchCategories';
import map from 'lodash.map';
import { useHistory } from 'react-router-dom';
import useTranslate from 'hooks/useTranslate';
import { toLinkFormat } from 'utils/text';
import { vsprintf } from 'sprintf-js';
import {
  SearchCategoriesDropdownItem,
  SearchCategoriesDropdownItemCount,
  SearchCategoriesDropdownItemLabel,
  SearchCategoriesDropdownItemLoader,
  SearchCategoriesDropdownWrap,
} from './SearchCategoriesDropdownStyles';

// Interfaces
interface ISearchCategoriesDropdown {
  value: string
  isPreCount?: boolean
  allResultsFirst?: boolean
  className?: string
}

// Component
const SearchCategoriesDropdown = (props: ISearchCategoriesDropdown) => {
  const {
    value,
    isPreCount = false,
    allResultsFirst = false,
    className = '',
  } = props;

  const {
    list,
  } = useSearchCategories({
    allResultsFirst,
  });

  const history = useHistory();

  const [getText] = useTranslate();

  const searchLink = toLinkFormat(value);

  return (
    <SearchCategoriesDropdownWrap
      className={className}
    >
      {map(list, (item) => {
        const {
          countState,
          count,
          preCount,
          path,
          label,
          countLoading,
          preCountLoading,
        } = item;

        const key = Array.isArray(countState)
          ? countState.join('_')
          : countState;

        const trueCount = isPreCount ? preCount : count;
        const trueLoading = isPreCount ? preCountLoading : countLoading;

        return (
          <SearchCategoriesDropdownItem
            key={key}
            onClick={() => {
              history.replace({
                pathname: path,
                search: `?search=${searchLink}`,
              });
            }}
          >
            <SearchCategoriesDropdownItemLabel>
              {Array.isArray(label) ? getText(label) : label}
              {trueLoading
                ? <SearchCategoriesDropdownItemLoader size={0.5} />
                : (
                  <SearchCategoriesDropdownItemCount>
                    {vsprintf(getText(['words', 'items']), [trueCount])}
                  </SearchCategoriesDropdownItemCount>
                )}
            </SearchCategoriesDropdownItemLabel>
          </SearchCategoriesDropdownItem>
        );
      })}
    </SearchCategoriesDropdownWrap>
  );
};

SearchCategoriesDropdown.defaultProps = {
  isPreCount: false,
  allResultsFirst: false,
  className: '',
};

export default SearchCategoriesDropdown;
