export enum States {
  LANG = 'lang',
  MODAL = 'modal',
  AUDIO = 'audio',
  ERROR = 'error',
  ERROR_BY_ALIAS = 'errorByAlias',
  PROFILE = 'profile',
  PROFILE_POST = 'profilePost',
  PROFILE_FAVORITES = 'profileFavorites',
  PROFILE_INTERESTS = 'profileInterests',
  PROFILE_HISTORY_JOURNALS = 'profileHistoryJournals',
  PROFILE_HISTORY_JOURNALS_FILTERS = 'profileHistoryJournalsFilters',
  PROFILE_HISTORY_ARTICLES = 'profileHistoryArticles',
  PROFILE_HISTORY_ARTICLES_FILTERS = 'profileHistoryArticlesFilters',
  PROFILE_BOOKMARKS_JOURNALS = 'profileBookmarksJournals',
  PROFILE_BOOKMARKS_JOURNALS_FILTERS = 'profileBookmarksJournalsFilters',
  PROFILE_BOOKMARKS_ARTICLES = 'profileBookmarksArticles',
  PROFILE_BOOKMARKS_ARTICLES_FILTERS = 'profileBookmarksArticlesFilters',
  PROFILE_FILTERS = 'profileFilters',
  FAQ = 'faq',
  OFFER = 'offer',
  ABOUT = 'about',
  JOURNALS = 'journals',
  JOURNALS_CATEGORIES = 'journalsCategories',
  JOURNALS_NEW = 'journalsNew',
  JOURNALS_RECOMMENDED = 'journalsRecommended',
  JOURNALS_RECOMMENDED_BY_CATEGORY = 'journalsRecommendedByCategory',
  JOURNALS_FREE = 'journalsFree',
  JOURNALS_COLLECTION = 'journalsCollection',
  JOURNALS_DETAIL = 'journalsDetail',
  JOURNALS_ISSUES = 'journalsIssues',
  JOURNALS_ISSUES_DETAIL = 'journalsIssuesDetail',
  JOURNALS_ISSUES_DETAIL_VIEW = 'journalsIssuesDetailView',
  JOURNALS_ISSUES_ARTICLES = 'journalsIssuesArticles',
  ARTICLES = 'articles',
  ARTICLES_TOP = 'articlesTop',
  ARTICLES_NEW = 'articlesNew',
  ARTICLES_LUCKY = 'articlesLucky',
  ARTICLES_DETAIL = 'articlesDetail',
  ARTICLES_CATEGORIES = 'articlesCategories',
  ARTICLES_RECOMMENDED = 'articlesRecommended',
  ACCESS = 'access',
  NEWS = 'news',
  NEWS_CATEGORIES = 'newsCategories',
  NEWS_NEW = 'newsNew',
  NEWS_DETAIL = 'newsDetail',
  NEWS_RECOMMENDED = 'newsRecommended',
  BOOKS = 'books',
  BOOKS_GENRES = 'booksGenres',
  BOOKS_DETAIL = 'booksDetail',
  BOOKS_POPULAR = 'booksPopular',
  BOOKS_RECOMMENDED = 'booksRecommended',
  BOOKS_PARENTS = 'booksParents',
  BOOKS_NEW = 'booksNew',
  BOOKS_SIMILAR = 'booksSimilar',
  BANNERS = 'banners',
  SEARCH_BOOKS = 'searchBooks',
  SEARCH_BOOKS_COUNT = 'searchBooksCount',
  SEARCH_BOOKS_PRE_COUNT = 'searchBooksPreCount',
  SEARCH_JOURNALS = 'searchJournals',
  SEARCH_JOURNALS_COUNT = 'searchJournalsCount',
  SEARCH_JOURNALS_PRE_COUNT = 'searchJournalsPreCount',
  SEARCH_ARTICLES = 'searchArticles',
  SEARCH_ARTICLES_COUNT = 'searchArticlesCount',
  SEARCH_ARTICLES_PRE_COUNT = 'searchArticlesPreCount',
  SEARCH_NEWS = 'searchNews',
  SEARCH_NEWS_COUNT = 'searchNewsCount',
  SEARCH_NEWS_PRE_COUNT = 'searchNewsPreCount',
}
