import styled from 'styled-components/macro';
import Breakpoints from '../../themes/constants/breakpoints';

export const BackButtonWrap = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 40px;
    cursor: pointer;
    @media only screen and (${Breakpoints.LAPTOP}) {
        display: none;
    }
`;
