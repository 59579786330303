import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as HomeIcon } from 'media/icons/home.svg';
import { ReactComponent as JournalsIcon } from 'media/icons/book-open.svg';
import { ReactComponent as BooksIcon } from 'media/icons/book-alt.svg';
import { ReactComponent as ArticlesIcon } from 'media/icons/receipt.svg';
import { ReactComponent as NewsIcon } from 'media/icons/window.svg';
import RoutePath from 'routes/constants/routes';

export interface IDesktopMenuItem {
  path: RoutePath
  titlePath: string[]
}
export type IDesktopMenu = IDesktopMenuItem[];

export interface IMobileMenuItem {
  path: RoutePath
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
  titlePath: string[]
}
export type IMobileMenu = IMobileMenuItem[];

export const desktopMenu: IDesktopMenu = [
  {
    path: RoutePath.BOOKS_CATEGORIES_PATH,
    titlePath: ['menu', 'books'],
  },
  {
    path: RoutePath.JOURNALS_CATEGORIES_PATH,
    titlePath: ['menu', 'journals'],
  },
  {
    path: RoutePath.ARTICLES_CATEGORIES_PATH,
    titlePath: ['menu', 'articles'],
  },
  {
    path: RoutePath.NEWS_CATEGORIES_PATH,
    titlePath: ['menu', 'news'],
  },
];

export const mobileMenu: IMobileMenu = [
  {
    path: RoutePath.ROOT_PATH,
    titlePath: ['menu', 'main'],
    icon: HomeIcon,
  },
  {
    path: RoutePath.BOOKS_CATEGORIES_PATH,
    titlePath: ['menu', 'books'],
    icon: BooksIcon,
  },
  {
    path: RoutePath.JOURNALS_CATEGORIES_PATH,
    titlePath: ['menu', 'journals'],
    icon: JournalsIcon,
  },
  {
    path: RoutePath.ARTICLES_CATEGORIES_PATH,
    titlePath: ['menu', 'articles'],
    icon: ArticlesIcon,
  },
  {
    path: RoutePath.NEWS_CATEGORIES_PATH,
    titlePath: ['menu', 'news'],
    icon: NewsIcon,
  },
];
