import styled from 'styled-components/macro';
import { ReactComponent as SearchIconSVG } from 'media/icons/search.svg';

export const SearchFieldWrap = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  flex-grow: 1;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '210px')};
  height: 40px;
  max-height: 40px;
  color: ${({ theme }) => theme.palette.black};
  z-index: 1000;
`;
export const SearchFieldSearchIcon = styled(SearchIconSVG)`
  position: absolute;
  top: 50%;
  left: 12px;
  width: 14px;
  height: 15px;
  color: #999;
  transform: translateY(-50%);
  pointer-events: none;
`;
export const SearchFieldInput = styled.input<{ isOpenDropdown?: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme, isOpenDropdown }) => (isOpenDropdown
    ? `${theme.borderRadius.field.search} ${theme.borderRadius.field.search} 0 0`
    : theme.borderRadius.field.search)};
  background: ${({ theme, isOpenDropdown }) => theme.background.fields.search[isOpenDropdown ? 'hover' : 'primary']};
  color: ${({ theme }) => theme.color.primary};
  border: ${({ theme }) => theme.border.fieldSearch};
  padding: 6px 50px 5px 37px;
  font-size: 14px;
  line-height: 1.2;
  box-shadow: ${({ isOpenDropdown }) => isOpenDropdown && '0 4px 20px rgba(0, 0, 0, 0.15)'};
  &:focus {
    background: ${({ theme }) => theme.background.fields.search.hover};
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.greyDark};
    font-size: 14px;
  }
`;
export const BasicButton = styled.b`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  cursor: pointer;
  &:active {
    opacity: 0.7;
  }
`;
export const SearchFieldSubmitButton = styled(BasicButton)`
  & > svg {
    width: 16px;
    height: 16px;
    color: #666;
  }
`;
export const SearchFieldClearButton = styled(BasicButton)`
  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: ${({ theme }) => theme.background.button.clear};
    width: 16px;
    height: 16px;
    & svg {
      stroke-width: 1px;
      width: 8px;
      height: 8px;
      color: ${({ theme }) => theme.color.button.clear};
    }
  }
`;
