import { ButtonThemeType } from 'components/Button/Button';

const primaryColor = '#9f248f';
const secondaryColor = '#ee255c';
const whiteColor = '#fff';
const greyColor = '#f2f2f2';
const greyMiddleColor = '#bebebe';
const greyDarkColor = '#6f6f6f';
const blackColor = '#000';
const darkPrimaryColor = '#262626';
const blueColor = '#342d8c';
const greenColor = '#b4d335';
const redGradient = 'linear-gradient(90deg, #da166e 0%, #ed205d 52.08%, #f03e3f 100%)';
const violetGradient = 'linear-gradient(90deg, #922790 0%, #94268F 10.79%, #9A218A 52.08%, #CE138C 100%)';
const blueGradient = 'linear-gradient(90deg, #2E2E8D 0%, #352E8D 52.08%, #6E2F92 100%)';
const orangeColor = '#f37631';

const themes = {
  palette: {
    primary: primaryColor,
    secondary: secondaryColor,
    primaryDark: darkPrimaryColor,
    white: whiteColor,
    black: blackColor,
    red: '#ff0000',
    grey: '#ccc',
    greyLight: greyColor,
    greyDark: greyDarkColor,
    greyMiddle: greyMiddleColor,
    blue: blueColor,
    green: greenColor,
    redGradient,
    violetGradient,
    blueGradient,
    orange: orangeColor,
  },
  color: {
    minor: '#c2c2c2',
    footer: '#fff',
    footerMinor: '#c2c2c2',
    primary: blackColor,
    secondary: '#ee255c',
    header: whiteColor,
    tabs: {
      categories: {
        active: whiteColor,
        inactive: greyMiddleColor,
      },
      collections: {
        active: secondaryColor,
        disabled: '#aaaaaa85',
        inactive: '#999',
      },
      profile: {
        active: {
          mobile: whiteColor,
          desktop: blackColor,
        },
      },
      search: {
        active: whiteColor,
      },
    },
    button: {
      primary: whiteColor,
      secondary: primaryColor,
      signIn: whiteColor,
      clear: whiteColor,
      slider: greyDarkColor,
      [ButtonThemeType.PRIMARY]: whiteColor,
      [ButtonThemeType.SECONDARY]: whiteColor,
      [ButtonThemeType.MINOR]: greyMiddleColor,
    },
    fields: {
      text: blackColor,
    },
    interests: {
      card: {
        default: greyDarkColor,
        active: secondaryColor,
        hover: blackColor,
      },
    },
    select: {
      option: darkPrimaryColor,
      value: darkPrimaryColor,
      arrow: darkPrimaryColor,
    },
    books: {
      read: {
        header: {
          mobile: greyDarkColor,
          desktop: greyMiddleColor,
        },
        nav: {
          button: greyDarkColor,
          label: '#bebebe',
        },
      },
    },
  },
  background: {
    profileContent: whiteColor,
    footer: violetGradient,
    tabs: {
      active: secondaryColor,
      tile: '#ececec',
      profile: {
        active: secondaryColor,
      },
      search: {
        minor: '#ececec',
      },
    },
    card: {
      journal: whiteColor,
      article: whiteColor,
      news: whiteColor,
      books: {
        primary: 'linear-gradient(90deg, #922790 0%, #9a218a 52.08%, #ce138c 100%)',
        secondary: 'linear-gradient(90deg, #f04d22 0%, #f26e23 52.08%, #fba61c 100%)',
      },
    },
    primary: whiteColor,
    main: whiteColor,
    line: '#e0e0e0',
    button: {
      primary: primaryColor,
      secondary: secondaryColor,
      slider: whiteColor,
      clear: '#666',
      [ButtonThemeType.PRIMARY]: primaryColor,
      [ButtonThemeType.SECONDARY]: secondaryColor,
      [ButtonThemeType.MINOR]: 'transparent',
    },
    header: whiteColor,
    fields: {
      search: {
        primary: greyColor,
        hover: whiteColor,
      },
      select: {
        primary: greyColor,
        hover: '#fff',
      },
      text: '#f2f2f2',
    },
    swiper: {
      scrollbar: {
        drag: {
          primary: blackColor,
          profile: secondaryColor,
        },
        scrollbar: {
          primary: whiteColor,
          profile: 'rgba(170, 170, 170, 0.4)',
        },
      },
    },
    thumb: '#cacaca',
    skeleton: '#ddd',
    select: {
      dropdown: whiteColor,
    },
  },
  margins: {
    title: {
      mobile: {
        marginBottom: '12px',
      },
      tablet: {
        marginBottom: '20px',
      },
      desktop: {
        marginBottom: '32px',
      },
    },
  },
  paddings: {
    inner: {
      mobile: {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      tablet: {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      desktop: {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
      card: {
        padding: '12px',
      },
    },
    wrap: {
      mobile: {
        paddingTop: '24px',
        paddingBottom: '24px',
      },
      tablet: {
        paddingTop: '24px',
        paddingBottom: '24px',
      },
      desktop: {
        paddingTop: '24px',
        paddingBottom: '24px',
      },
    },
  },
  border: {
    fieldSearch: '1px solid transparent',
  },
  borderColor: {
    button: {
      [ButtonThemeType.PRIMARY]: primaryColor,
      [ButtonThemeType.SECONDARY]: secondaryColor,
      [ButtonThemeType.MINOR]: greyMiddleColor,
    },
  },
  borderRadius: {
    primary: '12px',
    button: '12px',
    field: {
      search: '12px',
    },
    card: {
      journal: '12px',
      article: '12px',
      news: '12px',
      books: '12px',
    },
    wrap: {
      main: '24px',
    },
  },
  boxShadow: {
    card: {
      journal: '0px 24px 60px 0px rgba(92, 92, 92, 0.3)',
      article: '0 12px 48px 0 rgba(68, 68, 68, 0.1)',
      news: '0 12px 48px 0 rgba(68, 68, 68, 0.1)',
      books: '0px 24px 60px 0px rgba(92, 92, 92, 0.3)',
    },
    wrap: {
      main: '0px 24px 60px 0px rgba(92, 92, 92, 0.3)',
    },
  },
  fontFamily: {
    primary: '"EffraCorp", "Roboto", Arial, sans-serif',
  },
  width: {
    wrap: '1700px',
    view: '1100px',
    reader: '820px',
    thumb: '3px',
  },
  height: {
    header: {
      mobile: '60px',
      tablet: '80px',
      desktop: '100px',
    },
    footerIssuesBookmarks: {
      mobile: '80px',
      tablet: '80px',
      desktop: '60px',
    },
    audio: {
      mobile: '0',
      tablet: '0',
    },
    thumb: '3px',
  },
  transition: {
    fast: '.1s ease-in-out',
    medium: '.2s ease-in-out',
    long: '.3s ease-in-out',
  },
  filters: {
    interests: {
      active: 'invert(23%) sepia(69%) saturate(3126%) hue-rotate(328deg) brightness(95%) contrast(97%)',
      inactive: 'invert(64%) sepia(18%) saturate(14%) hue-rotate(352deg) brightness(93%) contrast(85%)',
      hover: 'invert(0%) sepia(0%) saturate(7500%) hue-rotate(312deg) brightness(100%) contrast(107%)',
    },
  },
};

export type ITheme = typeof themes;

export default themes;
