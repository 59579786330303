import get from 'lodash.get';
import isObject from 'lodash.isobject';
import map from 'lodash.map';
import mapKeys from 'lodash.mapkeys';
import mapValues from 'lodash.mapvalues';
import camelCase from 'lodash.camelcase';
import snakeCase from 'lodash.snakecase';
import flow from 'lodash.flow';

export const CONTENT_TYPE_JSON = 'application/json';

export const responseToJSON = (data: any, response: any) => {
  const responseContentType = get(response, ['content-type']);

  if (CONTENT_TYPE_JSON === responseContentType && (isObject(data) || Array.isArray(data))) {
    return data;
  }

  if (CONTENT_TYPE_JSON === responseContentType) {
    return JSON.parse(data);
  }

  return data;
};

export const toCamelCase = (data: any): any => {
  if (Array.isArray(data)) {
    return map(data, (item) => toCamelCase(item));
  }

  if (isObject(data)) {
    return flow([
      (arr) => mapKeys(arr, (value: any, key: any) => camelCase(key)),
      (arr) => mapValues(arr, (value: any) => toCamelCase(value)),
    ])(data);
  }

  return data;
};

export const toSnakeCase = (data: any): any => {
  if (Array.isArray(data)) {
    return map(data, (item) => toSnakeCase(item));
  }

  if (isObject(data)) {
    return flow([
      (arr) => mapKeys(arr, (value: any, key: any) => snakeCase(key)),
      (arr) => mapValues(arr, (value: any) => toSnakeCase(value)),
    ])(data);
  }

  return data;
};

export const responseToCamelCase = (data: any, response: any) => {
  const responseContentType = get(response, ['content-type']);

  if (CONTENT_TYPE_JSON === responseContentType && (isObject(data) || Array.isArray(data))) {
    return toCamelCase(data);
  }

  if (CONTENT_TYPE_JSON === responseContentType) {
    return toCamelCase(JSON.parse(data));
  }

  return data;
};
